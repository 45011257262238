import { Tag } from "antd";
import t from "../../../../app/i18n";

interface Props {
  style?: React.CSSProperties;
}

const AgentTipperTag = ({ style }: Props) => (
  <Tag color="geekblue" style={style}>
    {t("agent.attrs.tipper")}
  </Tag>
);

export default AgentTipperTag;
