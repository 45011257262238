import { Card, Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { formatAgentName } from "../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../common/utils/utils";
import CalcTypeTag from "../../../records/components/CalcTypeTag";
import { deleteCalcBlacklistActions } from "../../ducks";
import { CalcBlacklist, CalcBlacklistFilterPageResult } from "../../types";

interface Props {
  calcBlacklistPage: CalcBlacklistFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onUpdateClick: (calcBlacklist: CalcBlacklist) => void;
  onDelete: typeof deleteCalcBlacklistActions.request;
}

const CalcBlacklistTableView = ({ calcBlacklistPage, onPageChange, onUpdateClick, onDelete }: Props) => {
  const columns: ColumnsType<CalcBlacklist> = [
    {
      key: "calcType",
      title: t("calc.enums.calcType._label"),
      width: 130,
      render: (_, record) => <CalcTypeTag type={record.calcType} style={{ marginRight: 0 }} />
    },
    {
      key: "institutionId",
      title: t("calc.blacklist.attrs.institutionId"),
      width: 100,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.institution.name}</Ellipsis>
    },
    {
      key: "agentId",
      title: t("calc.blacklist.attrs.agentId"),
      width: 190,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{formatAgentName(record.agent)}</Ellipsis>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("calc.blacklist.helpers.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => onDelete({ id: record.id })}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Card className="card-box">
      <Table<CalcBlacklist>
        {...tableStandardProps()}
        columns={columns}
        dataSource={calcBlacklistPage.pageData}
        scroll={{ x: TableSizes.SMALL }}
        pagination={{
          ...paginationTableProps,
          current: calcBlacklistPage.pageIndex + 1,
          pageSize: calcBlacklistPage.pageSize,
          total: calcBlacklistPage.totalElementsCount,
          onChange: onPageChange
        }}
      />
    </Card>
  );
};

export default CalcBlacklistTableView;
