import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RcFile } from "antd/lib/upload";
import { generatePath } from "react-router-dom";
import t from "../../../../../../app/i18n";
import ActionButton from "../../../../../../common/components/buttons/ActionButton";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import FileTypeIconWithFilename from "../../../../../../common/components/icons/FileTypeIconWithFilename";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";
import UploadDragger from "../../../../../../common/components/views/UploadDragger";
import { PageSizes, TableSizes } from "../../../../../../common/constants";
import { formatFileSize, formatLocaleDateTime } from "../../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../../common/utils/utils";
import {
  deleteClientAttachmentActions,
  downloadClientAttachmentsAsZipActions,
  uploadClientAttachmentsActions
} from "../../../../ducks";
import { CLIENT_ROUTE_PATHS } from "../../../../paths";
import { Client, ClientAttachment } from "../../../../types";
import styles from "./ClientAttachmentsTableView.module.scss";

interface Props {
  client: Client;
  onDownloadAsZip: typeof downloadClientAttachmentsAsZipActions.request;
  onUpload?: typeof uploadClientAttachmentsActions.request;
  onDelete?: typeof deleteClientAttachmentActions.request;
}

const ClientAttachmentsTableView = ({ client, ...props }: Props) => {
  const handleAttachmentsUpload = (_: RcFile, fileList: RcFile[]): boolean => {
    if (fileList.length > 0) {
      const formData = new FormData();
      fileList.forEach(file => formData.append("files", file));
      fileList.splice(0, fileList.length);

      props.onUpload?.({ id: client.id, object: formData });
    }

    return false;
  };

  const columns: ColumnsType<ClientAttachment> = [
    {
      key: "createdAt",
      title: t("common.createdAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "filename",
      title: t("common.filename"),
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <FileTypeIconWithFilename contentType={record.file.contentType} filename={record.file.filename} ellipsis />
      )
    },
    {
      key: "size",
      title: t("common.filesize"),
      width: 100,
      render: (_, record) => formatFileSize(record.file.size)
    },
    {
      key: "actions",
      align: "right",
      width: 180,
      fixed: "right",
      render: (_, record) => (
        <>
          <ActionTextIcon
            path={generatePath(CLIENT_ROUTE_PATHS.attachment.to, { id1: client.id, id2: record.id })}
            target="_blank"
            icon="export"
            color="blue"
            text={t("common.open")}
          />

          {props.onDelete ? (
            <>
              <Divider type="vertical" />

              <Popconfirm
                title={t("common.deleteAttachmentConfirm")}
                icon={<PopconfirmDeleteIcon />}
                okText={t("common.yes")}
                cancelText={t("common.no")}
                okType="danger"
                onConfirm={() => props.onDelete?.({ id1: client.id, id2: record.id })}
              >
                <span>
                  <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
                </span>
              </Popconfirm>
            </>
          ) : null}
        </>
      )
    }
  ];

  return (
    <div>
      {client.attachments?.length ? (
        <div className={styles.tableHeaderDownloadActions}>
          <ActionButton icon="download" onClick={() => props.onDownloadAsZip({ id: client.id })}>
            {t("client.actions.downloadAttachmentsAsZip")}
          </ActionButton>
        </div>
      ) : undefined}
      <Table<ClientAttachment>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.SMALL }}
        dataSource={client.attachments}
        pagination={{ ...paginationTableProps, pageSize: PageSizes.LARGE, total: client.attachments?.length }}
      />

      {props.onUpload ? (
        <div className="margin-top-small margin-bottom-small">
          <UploadDragger multiple showUploadList={false} beforeUpload={handleAttachmentsUpload} />
        </div>
      ) : undefined}
    </div>
  );
};

export default ClientAttachmentsTableView;
