import { Button, Card, Checkbox, DatePicker, Flex, Form, Select } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { RootState } from "../../../../common/types";
import {
  datePickerClearableProps,
  datePickerFormItemProps,
  disableDatePickerFuture,
  mapInstitutionTreeSelectValuesToInstitutionIds,
  mapProductTreeSelectValuesToProductIds,
  rangePickerFormItemProps,
  selectStandardProps
} from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import AgentSelect from "../../../enumerations/components/form/AgentSelect";
import CommissionsLevelSelect from "../../../enumerations/components/form/CommissionsLevelSelect";
import InstitutionTreeSelect from "../../../enumerations/components/form/InstitutionTreeSelect";
import ProductTreeSelect from "../../../enumerations/components/form/ProductTreeSelect";
import { selectInstitutionsEnums, selectProductGroupsEnums } from "../../../enumerations/ducks";
import { InstitutionWithSettings, ProductGroupWithProducts } from "../../../enumerations/types";
import { PointsReportType } from "../enums";
import { PointsReportFilterRequest, PointsReportFilterResult } from "../types";

interface Props {
  filter: PointsReportFilterResult;
  onFilterSubmit: (filter: PointsReportFilterRequest) => void;
}

export const PointsReportFilterView = ({ filter, onFilterSubmit }: Props) => {
  const [form] = Form.useForm<PointsReportFilterRequest>();

  const institutionsEnums = useSelector<RootState, InstitutionWithSettings[]>(selectInstitutionsEnums);
  const productGroupsEnums = useSelector<RootState, ProductGroupWithProducts[]>(selectProductGroupsEnums);

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        periodDateRange:
          filter.periodStartDate && filter.periodEndDate ? [filter.periodStartDate, filter.periodEndDate] : undefined,
        institutionIds: filter.institutionIds || [],
        productIds: filter.productIds || [],
        commissionsSettingsLevelIds: filter.commissionsSettingsLevelIds || [],
        agentCreatedAtDateMin: filter.agentCreatedAtDateMin,
        reportType: filter.reportType,
        rootAgentId: filter.rootAgentId,
        onlyDirectSubordinates: filter.onlyDirectSubordinates,
        includeDeactivated: filter.includeDeactivated,
        includeRepresentatives: filter.includeRepresentatives,
        includeNonGainers: filter.includeNonGainers,
        includeWithoutPoints: filter.includeWithoutPoints
      });
    }
  }, [filter, form]);

  const handleFilterSubmit = ({ periodDateRange, ...filter }: PointsReportFilterRequest): void => {
    const processedFilter: PointsReportFilterRequest = {
      ...filter,
      periodStartDate: periodDateRange?.[0],
      periodEndDate: periodDateRange?.[1],
      institutionIds: mapInstitutionTreeSelectValuesToInstitutionIds(filter.institutionIds ?? [], institutionsEnums),
      productIds: mapProductTreeSelectValuesToProductIds(filter.productIds ?? [], productGroupsEnums)
    };

    onFilterSubmit(processedFilter);
  };

  const handleFilterClear = (): void => {
    form.resetFields();
    handleFilterSubmit(form.getFieldsValue());
  };

  const smallColSpan = 3;
  const colSpan = 4;

  return (
    <Card
      className="card-box"
      title={<h2>{t("commissions.points.titles.report")}</h2>}
      extra={
        <ActionButton color="danger" size="middle" variant="outlined" icon="reload" onClick={handleFilterClear}>
          {t("common.resetFilter")}
        </ActionButton>
      }
    >
      <Form form={form} layout="vertical" onFinish={handleFilterSubmit} name="pointsReportForm">
        <Flex gap="middle" align="end">
          <Form.Item
            style={{ flex: colSpan }}
            name="periodDateRange"
            label={t("commissions.points.filter.periodDateRange")}
            rules={[validations.none]}
            {...rangePickerFormItemProps("month")}
          >
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              format="MMMM YYYY"
              disabledDate={current => disableDatePickerFuture(current)}
              placeholder={[t("common.from"), t("common.to")]}
              picker="month"
            />
          </Form.Item>

          <InstitutionTreeSelect
            formItemProps={{
              style: { flex: colSpan },
              name: "institutionIds",
              label: t("commissions.points.filter.institutionIds")
            }}
          />

          <ProductTreeSelect
            formItemProps={{
              style: { flex: colSpan },
              name: "productIds",
              label: t("commissions.points.filter.productIds")
            }}
          />

          <CommissionsLevelSelect
            formItemProps={{
              style: { flex: colSpan },
              name: "commissionsSettingsLevelIds",
              label: t("agent.filter.commissionsSettingsLevelIds"),
              rules: [validations.none]
            }}
            selectProps={{
              allowClear: true,
              mode: "multiple",
              maxTagCount: "responsive"
            }}
            optionsProps={{ renderOnlyLevelCodeTag: true }}
          />

          <Form.Item
            style={{ flex: smallColSpan }}
            name="agentCreatedAtDateMin"
            label={t("agent.filter.agentCreatedAtDateMin")}
            rules={[validations.notFuture]}
            {...datePickerFormItemProps}
          >
            <DatePicker {...datePickerClearableProps} disabledDate={current => disableDatePickerFuture(current)} />
          </Form.Item>

          <Form.Item
            style={{ flex: smallColSpan }}
            name="reportType"
            label={t("commissions.points.enums.reportType._label")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={Object.keys(PointsReportType).map(type => ({
                value: type,
                label: t("commissions.points.enums.reportType." + type)
              }))}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
              {t("common.filter")}
            </Button>
          </Form.Item>
        </Flex>

        <Flex gap="middle">
          <AgentSelect
            formItemProps={{
              style: { width: `16.4%` },
              name: "rootAgentId",
              label: t("agent.filter.rootAgentId")
            }}
            selectProps={{ allowClear: true }}
          />

          <Form.Item
            name="onlyDirectSubordinates"
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("agent.filter.onlyDirectSubordinates")}</Checkbox>
          </Form.Item>

          <Form.Item
            name="includeDeactivated"
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("agent.filter.includeDeactivated")}</Checkbox>
          </Form.Item>

          <Form.Item
            name="includeRepresentatives"
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("agent.filter.includeRepresentatives")}</Checkbox>
          </Form.Item>

          <Form.Item
            name="includeNonGainers"
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("agent.filter.includeNonGainers")}</Checkbox>
          </Form.Item>

          <Form.Item
            name="includeWithoutPoints"
            className="form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>{t("commissions.points.filter.includeWithoutPoints")}</Checkbox>
          </Form.Item>
        </Flex>
      </Form>
    </Card>
  );
};
