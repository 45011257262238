import { Breadcrumb } from "antd";
import { BreadcrumbItemType, BreadcrumbSeparatorType } from "antd/es/breadcrumb/Breadcrumb";
import { MenuItemType } from "antd/es/menu/interface";
import { Pathname } from "history";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { RootState } from "../../../common/types";
import {
  PagesMap,
  replaceUrlPlaceholdersWithUuids,
  replaceUrlUuidsWithPlaceholders
} from "../../../common/utils/navigationUtils";
import { hasAnyPermission } from "../../../common/utils/utils";
import { selectIsUserAuthenticated, selectPermissions } from "../../../modules/auth/ducks";
import { selectNotFoundPageRendered, selectRouterLocationPathname } from "../../../modules/ducks";
import type { UUID } from "../../../typings/global";

export type BreadcrumbsItemType = Partial<BreadcrumbItemType & BreadcrumbSeparatorType>;

const renderBreadcrumb = (path: string, uuids: UUID[], accountPermissions: Permission[]): BreadcrumbsItemType[] => {
  const page = PagesMap.get(path);
  if (!page || page.hideBreadcrumbs) {
    return [];
  }

  let crumbs: BreadcrumbsItemType[] = [];
  const outputPath = replaceUrlPlaceholdersWithUuids(path, uuids);

  if (page.parent) {
    crumbs = renderBreadcrumb(page.parent, uuids, accountPermissions);
  }

  const siblings = page.siblings
    ? page.siblings.filter(sibling => !sibling.permissions || hasAnyPermission(accountPermissions, sibling.permissions))
    : [];

  if (siblings.length > 0) {
    const siblingsItems = siblings.map<MenuItemType>(sibling => {
      const siblingPage = PagesMap.get(sibling.path);
      const siblingPath = replaceUrlPlaceholdersWithUuids(sibling.path, uuids);
      return {
        key: siblingPath,
        label: <Link to={siblingPath}>{siblingPage?.render || siblingPage?.title}</Link>
      };
    });
    siblingsItems.unshift({ key: outputPath, label: <Link to={outputPath}>{page.render || page.title}</Link> });

    crumbs.push({
      title: page.render ?? page.title,
      menu: { items: siblingsItems }
    });
  } else {
    crumbs.push({
      title: <Link to={outputPath}>{page.render || page.title}</Link>
    });
  }

  return crumbs;
};

const BreadcrumbsBox = () => {
  const accountPermissions = useSelector<RootState, Permission[]>(selectPermissions);
  const locationPathname = useSelector<RootState, Pathname>(selectRouterLocationPathname);
  const notFoundPageRendered = useSelector<RootState, boolean>(selectNotFoundPageRendered);
  const isUserAuthenticated = useSelector<RootState, boolean | undefined>(selectIsUserAuthenticated);

  if (!notFoundPageRendered && isUserAuthenticated) {
    const currentPage = replaceUrlUuidsWithPlaceholders(locationPathname);
    const crumbs = renderBreadcrumb(currentPage.path, currentPage.uuids, accountPermissions);

    return (
      <div>
        {PagesMap.get(currentPage.path)?.wrappedStyle ? (
          <ContentWrapper wide={PagesMap.get(currentPage.path)?.wrappedStyle === "wide"}>
            <Breadcrumb items={crumbs} style={{ paddingBottom: 10 }} />
          </ContentWrapper>
        ) : (
          <Breadcrumb items={crumbs} style={{ paddingBottom: 10 }} />
        )}
      </div>
    );
  }

  return null;
};

export default BreadcrumbsBox;
