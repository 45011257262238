import { Drawer } from "antd";
import classNames from "classnames";
import { useState } from "react";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import { DrawerSizes } from "../../../../common/constants";
import { isDefinedValue } from "../../../../common/utils/utils";
import { ClientFormType } from "../../enums";
import { Client } from "../../types";
import ClientTypeTagWithDescription from "../tags/ClientTypeTagWithDescription";
import ClientTypeTagWithName from "../tags/ClientTypeTagWithName";
import ClientDetailView from "../views/detail/ClientDetailView";
import styles from "./ClientsListDrawerView.module.scss";

export interface ClientDataProps {
  client: Client;
  clientFormTypes: ClientFormType[];
  clientPolicyHolderType?: "insurance" | "loan";
}

interface Props {
  clientsData?: ClientDataProps[];
  className?: string;
}

export const ClientsListDrawerView = ({ clientsData, className }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [clientIndex, setClientIndex] = useState<number>();
  const clientFromData = clientsData ? clientsData[clientIndex as number]?.client : undefined;

  return (
    <>
      {isDefinedValue(clientIndex) && clientFromData && (
        <Drawer
          title={<ClientTypeTagWithName client={clientFromData} />}
          width={DrawerSizes.MEDIUM}
          open={drawerOpen}
          closable
          onClose={() => setDrawerOpen(false)}
          afterOpenChange={open => {
            if (!open) {
              setClientIndex(undefined);
            }
          }}
        >
          <ClientDetailView client={clientFromData} />
        </Drawer>
      )}

      <div className={classNames(styles.clientsWrapper, className)}>
        {clientsData?.map((data, index) => {
          const clientLabel = data.clientFormTypes?.length
            ? data.clientFormTypes
                .map(type =>
                  type === ClientFormType.POLICY_HOLDER && data.clientPolicyHolderType
                    ? t("client.helpers." + data.clientPolicyHolderType + "PolicyHolder")
                    : t("client.enums.formType." + type)
                )
                .join(", ")
            : t("client.helpers.client");

          return (
            <div key={index} className={styles.clientRow}>
              <span className={styles.clientLabel}>{clientLabel}</span>
              <span
                className={styles.clientsListItem}
                onClick={() => {
                  setClientIndex(index);
                  setDrawerOpen(true);
                }}
              >
                <ClientTypeTagWithDescription client={data.client} />
                <span className={classNames("margin-left-tiny", styles.detailIcon)}>
                  <ActionTextIcon icon="eye" color="blue" disableDelayEffect />
                </span>
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};
