import { Tag } from "antd";
import t from "../../../../app/i18n";
import { AgentType } from "../../enums";

interface Props {
  type: AgentType;
  style?: React.CSSProperties;
}

const AgentTypeTag = ({ type, style }: Props) => {
  let color: string;

  switch (type) {
    case AgentType.NATURAL:
      color = "blue";
      break;
    case AgentType.SELF_EMPLOYED:
      color = "gold";
      break;
    case AgentType.LEGAL:
      color = "purple";
      break;
  }

  return (
    <Tag style={{ width: 40, textAlign: "center", ...style }} color={color}>
      {t("agent.enums.type." + type)}
    </Tag>
  );
};

export default AgentTypeTag;
