import { Button, Col, Form, Input, Popconfirm, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import AntIcon from "../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { accessTreePathDelimiter, rowGutter } from "../../../../common/constants";
import { useFormErrorHandler } from "../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../common/utils/validationUtils";
import AgentSelect from "../../../enumerations/components/form/AgentSelect";
import { requests } from "../../api";
import {
  createAgentBrandingActions,
  deleteAgentBrandingActions,
  getAgentBrandingActions,
  updateAgentBrandingActions
} from "../../ducks";
import { AgentType } from "../../enums";
import { Agent, AgentBranding, CreateUpdateAgentBranding, LegalAgent } from "../../types";

interface Props {
  branding?: AgentBranding;
  agent: Agent;
  onGet: typeof getAgentBrandingActions.request;
  onCreate: typeof createAgentBrandingActions.request;
  onUpdate: typeof updateAgentBrandingActions.request;
  onDelete: typeof deleteAgentBrandingActions.request;
}

const AgentBrandingForm = ({ agent, branding, ...props }: Props) => {
  const [form] = Form.useForm<CreateUpdateAgentBranding>();
  useFormErrorHandler(form, "agent.branding.attrs", [requests.CREATE_AGENT_BRANDING, requests.UPDATE_AGENT_BRANDING]);

  useEffect(() => {
    props.onGet({ id: agent.id });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (branding) {
      form.setFieldsValue({
        ...branding,
        officialRepresentative: undefined,
        officialRepresentativeId: branding.officialRepresentative?.id,
        commissionsPayer: undefined,
        commissionsPayerId: branding.commissionsPayer?.id
      } as CreateUpdateAgentBranding | AgentBranding);
    } else {
      form.resetFields();
    }
  }, [branding, form]);

  const handleFormFinish = (values: CreateUpdateAgentBranding): void => {
    if (branding) {
      props.onUpdate({ id1: agent.id, id2: branding.id, object: values });
    } else {
      props.onCreate({ id: agent.id, object: values });
    }
  };

  const colSpan = 6;

  return (
    <>
      {branding && <ItemCreatedUpdatedInfoView item={branding} className="margin-bottom-medium" />}
      <Form form={form} layout="vertical" name="agentBrandingForm" onFinish={handleFormFinish}>
        {branding && <HiddenInput name="optimisticLockVersion" />}

        <Row gutter={rowGutter}>
          <Col span={colSpan * 2}>
            <Form.Item
              name="logoResourcePath"
              label={t("agent.branding.attrs.logoResourcePath")}
              rules={[validations.min(5)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name="primaryColor"
              label={t("agent.branding.attrs.primaryColor")}
              rules={[validations.pattern(regexPatterns.colorRegex)]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={colSpan}>
            <Form.Item
              name="secondaryColor"
              label={t("agent.branding.attrs.secondaryColor")}
              rules={[validations.pattern(regexPatterns.colorRegex)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan * 2}>
            <Form.Item
              name="emailSignatureTemplatePath"
              label={t("agent.branding.attrs.emailSignatureTemplatePath")}
              rules={[validations.min(6)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <AgentSelect
              formItemProps={{
                name: "officialRepresentativeId",
                label: t("agent.branding.attrs.officialRepresentativeId")
              }}
              selectProps={{
                allowClear: true,
                disabled: agent.type !== AgentType.LEGAL
              }}
              optionsProps={
                agent.type === AgentType.LEGAL
                  ? {
                      filter: agentOption =>
                        (agent as LegalAgent).representatives.some(repr => repr.id === agentOption.id)
                    }
                  : undefined
              }
            />
          </Col>
          <Col span={colSpan}>
            <AgentSelect
              formItemProps={{
                name: "commissionsPayerId",
                label: t("agent.branding.attrs.commissionsPayerId")
              }}
              selectProps={{
                allowClear: true
              }}
              optionsProps={{
                useAllAgents: true
              }}
            />
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={colSpan * 2}>
            <Form.Item
              name="officialRepresentativeSignatureResourcePath"
              label={t("agent.branding.attrs.officialRepresentativeSignatureResourcePath")}
              rules={[validations.min(5)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <div className="margin-top-medium">
          <Button className="margin-right-tiny" type="primary" htmlType="submit" icon={<AntIcon type="save" />}>
            {t(branding ? "common.save" : "common.create")}
          </Button>

          {branding && (
            <Popconfirm
              title={t("agent.branding.helpers.deleteBrandingConfirm", { aggregatedName: agent.aggregatedName })}
              icon={<PopconfirmDeleteIcon />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="danger"
              disabled={!agent.accessTreePath?.includes(accessTreePathDelimiter)}
              onConfirm={() => props.onDelete({ id1: agent.id, id2: branding.id })}
            >
              <Button
                icon={<AntIcon type="delete" />}
                danger
                disabled={!agent.accessTreePath?.includes(accessTreePathDelimiter)}
              >
                {t("common.delete")}
              </Button>
            </Popconfirm>
          )}
        </div>
      </Form>
    </>
  );
};

export default AgentBrandingForm;
