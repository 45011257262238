import t from "../../app/i18n";

export enum AgentType {
  NATURAL = "NATURAL",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  LEGAL = "LEGAL"
}

export enum TopAgentType {
  GENERIC = "GENERIC",
  SWELL = "SWELL",
  FINGO = "FINGO",
  PLUS = "PLUS"
}

export enum FinancialSector {
  INSURANCES_OR_REINSURANCES = "INSURANCES_OR_REINSURANCES",
  LOANS_AND_MORTGAGES = "LOANS_AND_MORTGAGES",
  CAPITAL_MARKET = "CAPITAL_MARKET",
  DEPOSITS = "DEPOSITS",
  SENIOR_PENSION_SAVINGS = "SENIOR_PENSION_SAVINGS",
  SUPPLEMENTARY_PENSION_SAVINGS = "SUPPLEMENTARY_PENSION_SAVINGS"
}

export enum AgentCompetenceType {
  TEST = "TEST",
  TRAINING = "TRAINING",
  OTHER = "OTHER"
}

export enum AgentCompetenceLevel {
  BASIC = "BASIC",
  MIDDLE = "MIDDLE",
  HIGHER = "HIGHER",
  HIGHEST = "HIGHEST"
}

export enum AgentCompetenceProvider {
  FINANCIAL_BROKERS_ASSOCIATION = "FINANCIAL_BROKERS_ASSOCIATION",
  SECURITIES_BROKERS_ASSOCIATION = "SECURITIES_BROKERS_ASSOCIATION",
  BROKER_CONSULTING = "BROKER_CONSULTING",
  EDUAKADEMIA = "EDUAKADEMIA",
  EFIBA = "EFIBA",
  INSTITUTE_OF_BANKING_EDUCATION = "INSTITUTE_OF_BANKING_EDUCATION",
  NBS = "NBS",
  OFV = "OFV",
  OVB = "OVB",
  PARTNERS_GROUP = "PARTNERS_GROUP",
  PROSIGHT_ACADEMY = "PROSIGHT_ACADEMY",
  SLOVAK_ASSOCIATION_OF_INSURANCE_COMPANIES = "SLOVAK_ASSOCIATION_OF_INSURANCE_COMPANIES",
  SLOVAK_BANK_ASSOCIATION = "SLOVAK_BANK_ASSOCIATION",
  STAKER = "STAKER",
  UNIVERSAL = "UNIVERSAL",
  WINNERS_GROUP = "WINNERS_GROUP"
}

export const agentTypeTMap = new Map<AgentType, string>(
  Object.values(AgentType).map(type => [
    type,
    `${t("agent.enums.type." + type)} ${t("agent.helpers.agentTypeLongName." + type)}`
  ])
);
