import { Popover, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import t from "../../../../../../../app/i18n";
import ActionTextIcon from "../../../../../../../common/components/icons/ActionTextIcon";
import TableActionsView from "../../../../../../../common/components/views/TableActionsView";
import { PageSizes, TableSizes } from "../../../../../../../common/constants";
import { Permission } from "../../../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../../../common/types";
import { formatLocaleCurrency, formatLocaleNettoPoints } from "../../../../../../../common/utils/formatUtils";
import { tInterval } from "../../../../../../../common/utils/translationUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../../../common/utils/utils";
import AgentTypeTagWithName from "../../../../../../agent/components/tags/AgentTypeTagWithName";
import { selectHasPermissions } from "../../../../../../auth/ducks";
import { CommissionsBatchAttachmentType } from "../../../../enums";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../../../paths";
import { CommissionsBatch, CommissionsBatchOutputAttachment } from "../../../../types";

interface Props {
  batch: CommissionsBatch;
}

const CommissionsBatchOutputAttachmentsTableView = ({ batch }: Props) => {
  const hasReadAgentPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.AGENT_READ)(state)
  );

  const columns: ColumnsType<CommissionsBatchOutputAttachment> = [
    {
      key: "gainer",
      title: t("commissions.batch.attrs.attachment.gainer"),
      width: 225,
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <AgentTypeTagWithName agent={record.gainer} ellipsis showLink={hasReadAgentPermission} linkTarget="_blank" />
      )
    },
    {
      key: "calculatedCommissionAmount",
      title: t("commissions.batch.attrs.attachment.calculatedCommissionAmount"),
      align: "right",
      width: 120,
      render: (_, record) => formatLocaleCurrency(record.calculatedCommissionAmount)
    },
    {
      key: "nettoPointAmount",
      title: t("commissions.batch.attrs.attachment.nettoPointAmount"),
      align: "right",
      width: 120,
      render: (_, record) => formatLocaleNettoPoints(record.nettoPointAmount)
    },
    {
      key: "specialCommissionAmount",
      title: t("commissions.batch.attrs.attachment.specialCommissionAmount"),
      align: "right",
      width: 100,
      render: (_, record) => (
        <Popover
          content={
            <>
              {record.specialCommissionCount > 0 && record.excludedSpecialCommissionCount > 0 ? (
                <>
                  {tInterval(
                    "commissions.batch.helpers.includedSpecialCommissionCountTooltip_interval",
                    record.specialCommissionCount
                  )}
                  <br />
                  {tInterval(
                    "commissions.batch.helpers.excludedSpecialCommissionCountTooltip_interval",
                    record.excludedSpecialCommissionCount
                  )}
                </>
              ) : record.specialCommissionCount > 0 ? (
                tInterval(
                  "commissions.batch.helpers.includedSpecialCommissionCountTooltip_interval",
                  record.specialCommissionCount
                )
              ) : record.excludedSpecialCommissionCount > 0 ? (
                tInterval(
                  "commissions.batch.helpers.excludedSpecialCommissionCountTooltip_interval",
                  record.excludedSpecialCommissionCount
                )
              ) : (
                t("commissions.batch.helpers.noSpecialCommissions")
              )}
            </>
          }
        >
          {!batch.stepChangeInProgress &&
          (record.specialCommissionCount > 0 || record.excludedSpecialCommissionCount > 0) ? (
            <span>
              <ActionTextIcon
                text={formatLocaleCurrency(record.specialCommissionAmount)}
                path={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.specialCommissions.to, {
                  id1: batch.id,
                  id2: record.gainer?.id
                })}
                color="blue"
              />
            </span>
          ) : (
            formatLocaleCurrency(record.specialCommissionAmount)
          )}
        </Popover>
      )
    },
    {
      key: "bailAccount",
      title: t("commissions.batch.attrs.attachment.bailAccount"),
      children: [
        {
          key: "bailAccountOpeningBalance",
          title: t("commissions.batch.attrs.attachment.bailAccountOpeningBalance"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.bailAccountOpeningBalance)
        },
        {
          key: "bailAccountAutomaticMovement",
          title: t("commissions.batch.attrs.attachment.bailAccountAutomaticMovement"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.bailAccountAutomaticMovement)
        },
        {
          key: "bailAccountManualMovement",
          title: t("commissions.batch.attrs.attachment.bailAccountManualMovement"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.bailAccountManualMovement)
        },
        {
          key: "bailAccountClosingBalance",
          title: t("commissions.batch.attrs.attachment.bailAccountClosingBalance"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.bailAccountClosingBalance)
        }
      ]
    },
    {
      key: "debtAccount",
      title: t("commissions.batch.attrs.attachment.debtAccount"),
      children: [
        {
          key: "debtAccountOpeningBalance",
          title: t("commissions.batch.attrs.attachment.debtAccountOpeningBalance"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.debtAccountOpeningBalance)
        },
        {
          key: "debtAccountMovement",
          title: t("commissions.batch.attrs.attachment.debtAccountMovement"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.debtAccountMovement)
        },
        {
          key: "debtAccountClosingBalance",
          title: t("commissions.batch.attrs.attachment.debtAccountClosingBalance"),
          align: "right",
          width: 100,
          render: (_, record) => formatLocaleCurrency(record.debtAccountClosingBalance)
        }
      ]
    },
    {
      key: "paidCommissionAmount",
      title: t("commissions.batch.attrs.attachment.paidCommissionAmount"),
      align: "right",
      width: 120,
      render: (_, record) => <b>{formatLocaleCurrency(record.paidCommissionAmount)}</b>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 115,
      render: (_, record) => (
        <TableActionsView
          actions={[
            {
              color: "blue",
              text: t("commissions.batch.actions.openCommissionsOutput"),
              icon: "eye",
              path: generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.attachment.to, { id1: batch.id, id2: record.id }),
              target: "_blank",
              disabled: batch.stepChangeInProgress
            }
          ]}
        />
      )
    }
  ];

  const attachments = batch.attachments.filter(
    a => a.type === CommissionsBatchAttachmentType.OUTPUT
  ) as CommissionsBatchOutputAttachment[];

  return (
    <Table<CommissionsBatchOutputAttachment>
      {...tableStandardProps()}
      columns={columns}
      scroll={{ x: TableSizes.HUGE }}
      dataSource={attachments}
      pagination={{
        ...paginationTableProps,
        pageSize: PageSizes.MEDIUM,
        total: attachments.length
      }}
    />
  );
};

export default CommissionsBatchOutputAttachmentsTableView;
