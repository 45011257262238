import { Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import t from "../../../../../../app/i18n";
import ActionButton from "../../../../../../common/components/buttons/ActionButton";
import DeleteIcon from "../../../../../../common/components/icons/DeleteIcon";
import { rowGutter } from "../../../../../../common/constants";
import {
  datePickerFormItemProps,
  datePickerStandardProps,
  disableDatePickerPresentAndFuture,
  selectStandardProps
} from "../../../../../../common/utils/formUtils";
import { regexPatterns, validations } from "../../../../../../common/utils/validationUtils";
import { TravelRiskGroup, TravelTerritorialValidity } from "../../../../../calculator/calcs/travel/enums";
import { ClientType } from "../../../../../client/enums";
import { Client, NaturalClient } from "../../../../../client/types";
import { CreateUpdateInsurance, CreateUpdateInsuranceContract, CreateUpdateTravelInsurance } from "../../../../types";

interface Props {
  index: number;
  clients: (Client | undefined)[];
  form: FormInstance<CreateUpdateInsuranceContract>;
}

const TravelInsuranceFormPart = ({ index, clients, form }: Props) => {
  const colSpan = 4;
  const formNameDataPrefix = ["insurances", index, "insuranceData"];

  const handlePolicyHolderIsAlsoInsuredChange = (policyHolderIsAlsoInsured: boolean): void => {
    const updatedInsurances = [...(form.getFieldValue(["insurances"]) || [])] as CreateUpdateInsurance[];
    const updatedTravel = updatedInsurances[index] as CreateUpdateTravelInsurance;
    const insuredClients: Record<string, any>[] = [...(updatedTravel.insuranceData.insuredClients || [])];

    if (policyHolderIsAlsoInsured) {
      if (clients[0]?.type === ClientType.NATURAL) {
        const policyHolder = clients[0] as NaturalClient;
        insuredClients[0] = {
          firstName: policyHolder.firstName,
          lastName: policyHolder.lastName,
          birthDate: policyHolder.birthDate
        };
      }
    } else {
      insuredClients[0] = {
        firstName: undefined,
        lastName: undefined,
        birthDate: undefined
      };
    }

    updatedInsurances[index] = {
      ...updatedTravel,
      insuranceData: { ...updatedTravel.insuranceData, insuredClients }
    } as CreateUpdateTravelInsurance;

    form.setFieldsValue({ insurances: updatedInsurances });
  };

  const handleInsuredClientRemove = (policyHolderIsAlsoInsured: boolean, onRemove: VoidFunction): void => {
    onRemove();
    if (policyHolderIsAlsoInsured) {
      handlePolicyHolderIsAlsoInsuredChange(policyHolderIsAlsoInsured);
    }
  };

  return (
    <>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "territorialValidity"]}
            label={t("calc.travel.enums.territorialValidity._label")}
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={Object.keys(TravelTerritorialValidity).map(validity => ({
                value: validity,
                label: t("calc.travel.enums.territorialValidity." + validity)
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...formNameDataPrefix, "riskGroup"]}
            label={t("calc.travel.enums.riskGroup._label")}
            rules={[validations.none]}
          >
            <Select
              {...selectStandardProps}
              allowClear
              options={Object.keys(TravelRiskGroup).map(group => ({
                value: group,
                label: t("calc.travel.enums.riskGroup." + group)
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left">{t("contract.attrs.insurances.insuranceData.insuredClients._label")}</Divider>

      <Row gutter={rowGutter}>
        <Col span={14}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.insurances?.[index]?.insuranceData.policyHolderIsAlsoInsured !==
              next.insurances?.[index]?.insuranceData.policyHolderIsAlsoInsured
            }
          >
            {({ getFieldValue }) => {
              const policyHolderIsAlsoInsured = getFieldValue([
                "insurances",
                index,
                "insuranceData",
                "policyHolderIsAlsoInsured"
              ]);
              return (
                <Form.List name={[...formNameDataPrefix, "insuredClients"]} initialValue={[{}]}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, i) => (
                        <Row gutter={rowGutter} key={field.key}>
                          <Col span={7}>
                            <Form.Item
                              {...field}
                              name={[field.name, "firstName"]}
                              label={t("contract.attrs.insurances.insuranceData.insuredClients.firstName")}
                              rules={[
                                validations.notBlank,
                                validations.size(1, 255),
                                validations.pattern(regexPatterns.uppercaseWordRegex)
                              ]}
                            >
                              <Input disabled={i === 0 && policyHolderIsAlsoInsured} />
                            </Form.Item>
                          </Col>

                          <Col span={7}>
                            <Form.Item
                              {...field}
                              name={[field.name, "lastName"]}
                              label={t("contract.attrs.insurances.insuranceData.insuredClients.lastName")}
                              rules={[
                                validations.notBlank,
                                validations.size(1, 255),
                                validations.pattern(regexPatterns.uppercaseWordRegex)
                              ]}
                            >
                              <Input disabled={i === 0 && policyHolderIsAlsoInsured} />
                            </Form.Item>
                          </Col>

                          <Col span={7}>
                            <Form.Item
                              {...field}
                              name={[field.name, "birthDate"]}
                              label={t("contract.attrs.insurances.insuranceData.insuredClients.birthDate")}
                              rules={[validations.notNull, validations.notPresentAndFuture]}
                              {...datePickerFormItemProps}
                            >
                              <DatePicker
                                {...datePickerStandardProps}
                                disabled={i === 0 && policyHolderIsAlsoInsured}
                                showToday={false}
                                disabledDate={disableDatePickerPresentAndFuture}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={3}>
                            {fields.length > 1 && (
                              <DeleteIcon
                                onClick={() =>
                                  handleInsuredClientRemove(policyHolderIsAlsoInsured, () => remove(field.name))
                                }
                              />
                            )}
                          </Col>
                        </Row>
                      ))}

                      <ActionButton icon="plus" onClick={() => add()}>
                        {t("contract.actions.addInsuredClient")}
                      </ActionButton>
                    </>
                  )}
                </Form.List>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item
            name={[...formNameDataPrefix, "policyHolderIsAlsoInsured"]}
            className="form-item-without-label"
            valuePropName="checked"
            initialValue={false}
            rules={[validations.none]}
          >
            <Checkbox
              style={{ maxHeight: 22 }}
              disabled={clients[0]?.type !== ClientType.NATURAL}
              onChange={e => handlePolicyHolderIsAlsoInsuredChange(e.target.checked)}
            >
              {t("contract.attrs.insurances.insuranceData.policyHolderIsAlsoInsured")}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default TravelInsuranceFormPart;
