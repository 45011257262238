import { Card, Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import t from "../../../../../../app/i18n";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../../common/constants";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../../common/types";
import { formatLocaleCurrencyWithNullAsZero, formatLocaleDateTime } from "../../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../../common/utils/utils";
import { selectHasPermissions } from "../../../../../auth/ducks";
import { deleteCommissionsBatchActions } from "../../../ducks";
import { CommissionsBatchStep } from "../../../enums";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../../paths";
import { CommissionsBatch, CommissionsBatchFilterPageResult } from "../../../types";
import CommissionsBatchStepTag from "../../CommissionsBatchStepTag";

interface Props {
  batchesPage: CommissionsBatchFilterPageResult;
  onDelete: typeof deleteCommissionsBatchActions.request;
  onUpdateClick(batch: CommissionsBatch): void;
  onPageChange(pageNumber: number): void;
}

const CommissionsBatchTableView = (props: Props) => {
  const hasManageCommissionsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.COMMISSIONS_MANAGE)(state)
  );

  const columns: ColumnsType<CommissionsBatch> = [
    {
      key: "name",
      title: t("commissions.batch.attrs.name"),
      width: 305,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
    },
    {
      key: "step",
      title: t("commissions.batch.enums.batchStep._label"),
      width: 115,
      render: (_, record) => <CommissionsBatchStepTag step={record.step} style={{ marginRight: 0 }} />
    },
    {
      key: "finishedAt",
      title: t("commissions.batch.attrs.finishedAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.finishedAt)
    },
    {
      key: "allCommissionAmount",
      title: t("commissions.batch.helpers.allCommissionAmount"),
      align: "right",
      width: 150,
      render: (_, record) => formatLocaleCurrencyWithNullAsZero(record.allCommissionAmount)
    },
    {
      key: "volumeCommissionAmount",
      title: t("commissions.batch.helpers.volumeCommissionAmountDesc"),
      align: "right",
      width: 150,
      render: (_, record) => formatLocaleCurrencyWithNullAsZero(record.volumeCommissionAmount)
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 270,
      render: (_, record) => (
        <>
          <ActionTextIcon
            path={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: record.id })}
            icon="eye"
            color="green"
            text={t("common.show")}
          />

          {hasManageCommissionsPermission && (
            <>
              <Divider type="vertical" />

              <ActionTextIcon
                icon="edit"
                color="blue"
                text={t("common.edit")}
                disabled={record.step === CommissionsBatchStep.FINISH}
                onClick={() => props.onUpdateClick(record)}
              />

              <Divider type="vertical" />

              <Popconfirm
                title={t("commissions.batch.helpers.deleteBatchConfirm")}
                icon={<PopconfirmDeleteIcon />}
                okText={t("common.yes")}
                cancelText={t("common.no")}
                okType="danger"
                onConfirm={() => props.onDelete({ id: record.id })}
              >
                <span>
                  <ActionTextIcon
                    icon="delete"
                    color="red"
                    text={t("common.delete")}
                    disabled={record.step === CommissionsBatchStep.FINISH}
                  />
                </span>
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];

  return (
    <Card className="card-box">
      <Table<CommissionsBatch>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.LARGE }}
        dataSource={props.batchesPage.pageData}
        pagination={{
          ...paginationTableProps,
          current: props.batchesPage.pageIndex + 1,
          pageSize: props.batchesPage.pageSize,
          total: props.batchesPage.totalElementsCount,
          onChange: props.onPageChange
        }}
      />
    </Card>
  );
};

export default CommissionsBatchTableView;
