import { Permission } from "../../common/security/authorization/enums";
import { FinancialSector } from "../agent/enums";
import { ProductFinancialSector } from "../product/enums";

export enum DefaultPermissionsSet {
  TIPPER = "TIPPER",
  AGENT = "AGENT",
  MANAGER = "MANAGER",
  BACK_OFFICE = "BACK_OFFICE",
  ADMINISTRATOR = "ADMINISTRATOR"
}

export const defaultPermissionsMap = new Map<DefaultPermissionsSet, Permission[]>([
  [
    DefaultPermissionsSet.TIPPER,
    [
      Permission.CLIENT_READ,
      Permission.INSURANCE_READ,
      Permission.LOAN_READ,
      Permission.INVESTMENT_READ,
      Permission.DEPOSIT_READ,
      Permission.SECOND_PILLAR_READ,
      Permission.THIRD_PILLAR_READ,
      Permission.GENERIC_READ
    ]
  ],
  [
    DefaultPermissionsSet.AGENT,
    [
      Permission.PROFILE_COMMISSIONS,
      Permission.PROFILE_COMPETENCE,
      Permission.PROFILE_LICENSE,
      Permission.PROFILE_BROKER_NUMBER,
      Permission.CLIENT_READ,
      Permission.CLIENT_CREATE,
      Permission.CLIENT_UPDATE,
      Permission.CLIENT_ACCOUNTS,
      Permission.INSURANCE_READ,
      Permission.INSURANCE_CREATE,
      Permission.INSURANCE_UPDATE,
      Permission.LOAN_READ,
      Permission.LOAN_CREATE,
      Permission.LOAN_UPDATE,
      Permission.INVESTMENT_READ,
      Permission.INVESTMENT_CREATE,
      Permission.INVESTMENT_UPDATE,
      Permission.DEPOSIT_READ,
      Permission.DEPOSIT_CREATE,
      Permission.DEPOSIT_UPDATE,
      Permission.SECOND_PILLAR_READ,
      Permission.SECOND_PILLAR_CREATE,
      Permission.SECOND_PILLAR_UPDATE,
      Permission.THIRD_PILLAR_READ,
      Permission.THIRD_PILLAR_CREATE,
      Permission.THIRD_PILLAR_UPDATE,
      Permission.GENERIC_READ,
      Permission.GENERIC_CREATE,
      Permission.GENERIC_UPDATE,
      Permission.MTPL_CALCULATE,
      Permission.MTPL_GENERATE_CONTRACT,
      Permission.CRASH_CALCULATE,
      Permission.CRASH_GENERATE_CONTRACT,
      Permission.REALTY_CALCULATE,
      Permission.REALTY_GENERATE_CONTRACT,
      Permission.TRAVEL_CALCULATE,
      Permission.TRAVEL_GENERATE_CONTRACT,
      Permission.CARD_READER,
      Permission.PARTNER_INSURANCE_COMPANIES,
      Permission.PARTNER_BANKS,
      Permission.PARTNER_SECURITIES_BROKERS,
      Permission.PARTNER_BUILDING_SAVINGS_BANKS,
      Permission.PARTNER_MANAGERIAL_PENSION_COMPANIES,
      Permission.PARTNER_SUPPLEMENTARY_PENSION_COMPANIES,
      Permission.PARTNER_OTHERS,
      Permission.AGENT_READ
    ]
  ],
  [
    DefaultPermissionsSet.MANAGER,
    [
      Permission.PROFILE_COMMISSIONS,
      Permission.PROFILE_COMPETENCE,
      Permission.PROFILE_LICENSE,
      Permission.PROFILE_BROKER_NUMBER,
      Permission.CLIENT_READ,
      Permission.CLIENT_CREATE,
      Permission.CLIENT_UPDATE,
      Permission.CLIENT_ACCOUNTS,
      Permission.INSURANCE_READ,
      Permission.INSURANCE_CREATE,
      Permission.INSURANCE_UPDATE,
      Permission.LOAN_READ,
      Permission.LOAN_CREATE,
      Permission.LOAN_UPDATE,
      Permission.INVESTMENT_READ,
      Permission.INVESTMENT_CREATE,
      Permission.INVESTMENT_UPDATE,
      Permission.DEPOSIT_READ,
      Permission.DEPOSIT_CREATE,
      Permission.DEPOSIT_UPDATE,
      Permission.SECOND_PILLAR_READ,
      Permission.SECOND_PILLAR_CREATE,
      Permission.SECOND_PILLAR_UPDATE,
      Permission.THIRD_PILLAR_READ,
      Permission.THIRD_PILLAR_CREATE,
      Permission.THIRD_PILLAR_UPDATE,
      Permission.GENERIC_READ,
      Permission.GENERIC_CREATE,
      Permission.GENERIC_UPDATE,
      Permission.MTPL_CALCULATE,
      Permission.MTPL_GENERATE_CONTRACT,
      Permission.CRASH_CALCULATE,
      Permission.CRASH_GENERATE_CONTRACT,
      Permission.REALTY_CALCULATE,
      Permission.REALTY_GENERATE_CONTRACT,
      Permission.TRAVEL_CALCULATE,
      Permission.TRAVEL_GENERATE_CONTRACT,
      Permission.CARD_READER,
      Permission.PARTNER_INSURANCE_COMPANIES,
      Permission.PARTNER_BANKS,
      Permission.PARTNER_SECURITIES_BROKERS,
      Permission.PARTNER_BUILDING_SAVINGS_BANKS,
      Permission.PARTNER_MANAGERIAL_PENSION_COMPANIES,
      Permission.PARTNER_SUPPLEMENTARY_PENSION_COMPANIES,
      Permission.PARTNER_OTHERS,
      Permission.AGENT_READ,
      Permission.AGENT_COMPETENCE,
      Permission.AGENT_LICENSE,
      Permission.AGENT_BROKER_NUMBER,
      Permission.AGENT_COMMISSIONS
    ]
  ],
  [
    DefaultPermissionsSet.BACK_OFFICE,
    [
      Permission.CLIENT_READ,
      Permission.CLIENT_CREATE,
      Permission.CLIENT_UPDATE,
      Permission.CLIENT_DELETE,
      Permission.CLIENT_ACCOUNTS,
      Permission.INSURANCE_READ,
      Permission.INSURANCE_CREATE,
      Permission.INSURANCE_UPDATE,
      Permission.INSURANCE_DELETE,
      Permission.INSURANCE_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_INSURANCE,
      Permission.LOAN_READ,
      Permission.LOAN_CREATE,
      Permission.LOAN_UPDATE,
      Permission.LOAN_DELETE,
      Permission.LOAN_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_LOAN,
      Permission.INVESTMENT_READ,
      Permission.INVESTMENT_CREATE,
      Permission.INVESTMENT_UPDATE,
      Permission.INVESTMENT_DELETE,
      Permission.INVESTMENT_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_INVESTMENT,
      Permission.DEPOSIT_READ,
      Permission.DEPOSIT_CREATE,
      Permission.DEPOSIT_UPDATE,
      Permission.DEPOSIT_DELETE,
      Permission.DEPOSIT_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_DEPOSIT,
      Permission.SECOND_PILLAR_READ,
      Permission.SECOND_PILLAR_CREATE,
      Permission.SECOND_PILLAR_UPDATE,
      Permission.SECOND_PILLAR_DELETE,
      Permission.SECOND_PILLAR_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_SECOND_PILLAR,
      Permission.THIRD_PILLAR_READ,
      Permission.THIRD_PILLAR_CREATE,
      Permission.THIRD_PILLAR_UPDATE,
      Permission.THIRD_PILLAR_DELETE,
      Permission.THIRD_PILLAR_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_THIRD_PILLAR,
      Permission.GENERIC_READ,
      Permission.GENERIC_CREATE,
      Permission.GENERIC_UPDATE,
      Permission.GENERIC_DELETE,
      Permission.GENERIC_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_GENERIC,
      Permission.PARTNER_INSURANCE_COMPANIES,
      Permission.PARTNER_BANKS,
      Permission.PARTNER_SECURITIES_BROKERS,
      Permission.PARTNER_BUILDING_SAVINGS_BANKS,
      Permission.PARTNER_MANAGERIAL_PENSION_COMPANIES,
      Permission.PARTNER_SUPPLEMENTARY_PENSION_COMPANIES,
      Permission.PARTNER_OTHERS,
      Permission.AGENT_READ,
      Permission.AGENT_CREATE,
      Permission.AGENT_UPDATE,
      Permission.AGENT_COMPETENCE,
      Permission.AGENT_COMPETENCE_MANAGE,
      Permission.AGENT_LICENSE,
      Permission.AGENT_LICENSE_MANAGE,
      Permission.AGENT_BROKER_NUMBER,
      Permission.AGENT_BROKER_NUMBER_MANAGE,
      Permission.AGENT_COMMISSIONS,
      Permission.AGENT_COMMISSIONS_SETTINGS
    ]
  ]
]);

export const financialSectorPermissionsMap = new Map<FinancialSector | ProductFinancialSector.OTHERS, Permission[]>([
  [
    FinancialSector.INSURANCES_OR_REINSURANCES,
    [
      Permission.INSURANCE_READ,
      Permission.INSURANCE_CREATE,
      Permission.INSURANCE_UPDATE,
      Permission.INSURANCE_DELETE,
      Permission.INSURANCE_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_INSURANCE,
      Permission.MTPL_CALCULATE,
      Permission.MTPL_GENERATE_CONTRACT,
      Permission.CRASH_CALCULATE,
      Permission.CRASH_GENERATE_CONTRACT,
      Permission.REALTY_CALCULATE,
      Permission.REALTY_GENERATE_CONTRACT,
      Permission.TRAVEL_CALCULATE,
      Permission.TRAVEL_GENERATE_CONTRACT,
      Permission.CARD_READER,
      Permission.PARTNER_INSURANCE_COMPANIES
    ]
  ],
  [
    FinancialSector.LOANS_AND_MORTGAGES,
    [
      Permission.LOAN_READ,
      Permission.LOAN_CREATE,
      Permission.LOAN_UPDATE,
      Permission.LOAN_DELETE,
      Permission.LOAN_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_LOAN,
      Permission.PARTNER_BANKS
    ]
  ],
  [
    FinancialSector.CAPITAL_MARKET,
    [
      Permission.INVESTMENT_READ,
      Permission.INVESTMENT_CREATE,
      Permission.INVESTMENT_UPDATE,
      Permission.INVESTMENT_DELETE,
      Permission.INVESTMENT_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_INVESTMENT,
      Permission.PARTNER_SECURITIES_BROKERS
    ]
  ],
  [
    FinancialSector.DEPOSITS,
    [
      Permission.DEPOSIT_READ,
      Permission.DEPOSIT_CREATE,
      Permission.DEPOSIT_UPDATE,
      Permission.DEPOSIT_DELETE,
      Permission.DEPOSIT_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_DEPOSIT,
      Permission.PARTNER_BUILDING_SAVINGS_BANKS
    ]
  ],
  [
    FinancialSector.SENIOR_PENSION_SAVINGS,
    [
      Permission.SECOND_PILLAR_READ,
      Permission.SECOND_PILLAR_CREATE,
      Permission.SECOND_PILLAR_UPDATE,
      Permission.SECOND_PILLAR_DELETE,
      Permission.SECOND_PILLAR_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_SECOND_PILLAR,
      Permission.PARTNER_MANAGERIAL_PENSION_COMPANIES
    ]
  ],
  [
    FinancialSector.SUPPLEMENTARY_PENSION_SAVINGS,
    [
      Permission.THIRD_PILLAR_READ,
      Permission.THIRD_PILLAR_CREATE,
      Permission.THIRD_PILLAR_UPDATE,
      Permission.THIRD_PILLAR_DELETE,
      Permission.THIRD_PILLAR_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_THIRD_PILLAR,
      Permission.PARTNER_SUPPLEMENTARY_PENSION_COMPANIES
    ]
  ],
  [
    ProductFinancialSector.OTHERS,
    [
      Permission.GENERIC_READ,
      Permission.GENERIC_CREATE,
      Permission.GENERIC_UPDATE,
      Permission.GENERIC_DELETE,
      Permission.GENERIC_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_GENERIC,
      Permission.PARTNER_OTHERS
    ]
  ]
]);
