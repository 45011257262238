import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { SearchPageResult } from "../../../../common/types";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { deleteAffiliatePartnerActions } from "../../ducks";
import { AffiliatePartner } from "../../types";

interface Props {
  affiliatePartnerPage: SearchPageResult<AffiliatePartner>;
  onPageChange: (pageNumber: number) => void;
  onUpdateClick: (affiliatePartner: AffiliatePartner) => void;
  onDelete: typeof deleteAffiliatePartnerActions.request;
}

const AffiliatePartnerTableView = ({ affiliatePartnerPage, onPageChange, onUpdateClick, onDelete }: Props) => {
  const columns: ColumnsType<AffiliatePartner> = [
    {
      key: "name",
      title: t("affiliatePartner.attrs.name"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("affiliatePartner.helpers.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => onDelete({ id: record.id })}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Table<AffiliatePartner>
      {...tableStandardProps()}
      columns={columns}
      scroll={{ x: TableSizes.SMALL }}
      dataSource={affiliatePartnerPage.pageData}
      pagination={{
        ...paginationTableProps,
        current: affiliatePartnerPage.pageIndex + 1,
        pageSize: affiliatePartnerPage.pageSize,
        total: affiliatePartnerPage.totalElementsCount,
        onChange: onPageChange
      }}
    />
  );
};

export default AffiliatePartnerTableView;
