import { ConfigProvider, Flex, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../../../common/components/views/Ellipsis";
import { formatLocaleCurrency, formatLocaleDate } from "../../../../../../common/utils/formatUtils";
import ContractAmountInfoPopover from "../../../../../contract/components/ContractAmountInfoPopover";
import { CONTRACT_ROUTE_PATHS } from "../../../../../contract/paths";
import { ContractList } from "../../../../../contract/types";
import styles from "./ClientPortfolioView.module.scss";

export type ClientPortfolioContracts = {
  riskContracts: ContractList[];
  reserveContracts: ContractList[];
  propertyContracts: ContractList[];
  futureContracts: ContractList[];
};

type Props = {
  contracts: ClientPortfolioContracts;
};

export const ClientPortfolioView = ({ contracts }: Props) => {
  const { t } = useTranslation();

  const riskContractsActive = contracts.riskContracts.length > 0;
  const reserveContractsActive = contracts.reserveContracts.length > 0;
  const propertyContractsActive = contracts.propertyContracts.length > 0;
  const futureContractsActive = contracts.futureContracts.length > 0;

  const portfolioCovered =
    riskContractsActive && reserveContractsActive && propertyContractsActive && futureContractsActive;

  const columns: ColumnsType<ContractList> = [
    {
      key: "effectiveBeginningDate",
      title: t("common.startDate"),
      render: (_, record) => formatLocaleDate(record.effectiveBeginningDate || record.signDate)
    },
    {
      key: "institution",
      title: t("common.institution"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.institution.name}</Ellipsis>
    },
    {
      key: "product",
      title: t("common.product"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.product.name}</Ellipsis>
    },
    {
      key: "amount",
      title: <ContractAmountInfoPopover />,
      render: (_, record) =>
        formatLocaleCurrency(record.annualPremium || record.approvedAmount || record.targetAmount || record.tradeAmount)
    },
    {
      key: "icon",
      width: 30,
      render: (_, record) => (
        <Tooltip title={t("common.detail")}>
          <span>
            <ActionTextIcon
              path={generatePath(CONTRACT_ROUTE_PATHS.detail.to, { id: record.id })}
              icon="eye"
              color="blue"
              target="_blank"
            />
          </span>
        </Tooltip>
      )
    }
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: { headerBg: "transparent", headerSplitColor: "transparent", headerColor: "white" }
        },
        token: { colorBgContainer: "transparent", colorText: "white", colorSplit: "transparent" }
      }}
    >
      <div className={styles.wrapper}>
        <Flex className={styles.row}>
          <div className={styles.box}>
            <div className={classNames(styles.content, { [styles.contentActiveLeft as string]: riskContractsActive })}>
              <h3 className={styles.title}>{t("product.enums.productGroupCategory.RISK")}</h3>
              {contracts.riskContracts.length ? (
                <Table<ContractList>
                  sticky={true}
                  rowKey="id"
                  size="small"
                  dataSource={contracts.riskContracts}
                  columns={columns}
                  scroll={contracts.riskContracts.length > 5 ? { y: 194 } : undefined}
                  pagination={false}
                />
              ) : undefined}
            </div>
          </div>
          <div className={styles.verticalLineBoxTop}>
            <div className={classNames(styles.line, { [styles.lineActive as string]: portfolioCovered })} />
          </div>
          <div className={styles.box}>
            <div
              className={classNames(styles.content, { [styles.contentActiveRight as string]: reserveContractsActive })}
            >
              <h3 className={styles.title}>{t("product.enums.productGroupCategory.RESERVE")}</h3>
              {contracts.reserveContracts.length ? (
                <Table<ContractList>
                  size="small"
                  rowKey="id"
                  dataSource={contracts.reserveContracts}
                  columns={columns}
                  scroll={contracts.reserveContracts.length > 5 ? { y: 194 } : undefined}
                  pagination={false}
                />
              ) : undefined}
            </div>
          </div>
        </Flex>
        <div className={styles.horizontalLineBox}>
          <div className={classNames(styles.line, { [styles.lineActive as string]: portfolioCovered })} />
        </div>
        <Flex className={styles.row}>
          <div className={styles.box}>
            <div
              className={classNames(styles.content, { [styles.contentActiveLeft as string]: propertyContractsActive })}
            >
              <h3 className={styles.title}>{t("product.enums.productGroupCategory.PROPERTY")}</h3>
              {contracts.propertyContracts.length ? (
                <Table<ContractList>
                  size="small"
                  rowKey="id"
                  dataSource={contracts.propertyContracts}
                  columns={columns}
                  scroll={contracts.propertyContracts.length > 5 ? { y: 194 } : undefined}
                  pagination={false}
                />
              ) : undefined}
            </div>
          </div>
          <div className={styles.verticalLineBoxBottom}>
            <div className={classNames(styles.line, { [styles.lineActive as string]: portfolioCovered })} />
          </div>
          <div className={styles.box}>
            <div
              className={classNames(styles.content, { [styles.contentActiveRight as string]: futureContractsActive })}
            >
              <h3 className={styles.title}>{t("product.enums.productGroupCategory.FUTURE")}</h3>
              {contracts.futureContracts.length ? (
                <Table<ContractList>
                  size="small"
                  rowKey="id"
                  dataSource={contracts.futureContracts}
                  columns={columns}
                  scroll={contracts.futureContracts.length > 5 ? { y: 194 } : undefined}
                  pagination={false}
                />
              ) : undefined}
            </div>
          </div>
        </Flex>
      </div>
    </ConfigProvider>
  );
};
