import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, Space } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useState } from "react";
import t from "../../../../../app/i18n";
import HiddenInput from "../../../../../common/components/form/components/HiddenInput";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import ContactsForm from "../../../../../common/modules/contact/ContactsForm";
import { ContactType, MarketingConsent } from "../../../../../common/modules/enums";
import {
  formatAgentShortIdNumber,
  formatAgentStructureIdNumber,
  formatIban,
  formatPhoneNumber
} from "../../../../../common/utils/formatUtils";
import {
  getAllFieldsNames,
  resolveFormValidationError,
  selectTagsStandardProps,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { contains, removeStringWhiteSpaces } from "../../../../../common/utils/utils";
import { validations } from "../../../../../common/utils/validationUtils";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import { requests } from "../../../api";
import { createAgentActions, updateAgentActions } from "../../../ducks";
import { AgentType, agentTypeTMap } from "../../../enums";
import { Agent, AgentSearchProps, CreateUpdateAgent, LegalAgent } from "../../../types";
import { AgentTypeLongNameTag } from "../../tags/AgenTypeLongNameTag";
import AgentAddressesFormPart from "./AgentAddressesFormPart";
import LegalAgentDataFormPart from "./LegalAgentDataFormPart";
import NaturalAgentDataFormPart from "./NaturalAgentDataFormPart";
import SelfEmployedAgentDataFormPart from "./SelfEmployedAgentDataFormPart";

interface Props {
  agent?: Agent;
  agentSearch?: AgentSearchProps;
  onCreate?: typeof createAgentActions.request;
  onUpdate?: typeof updateAgentActions.request;
  onCancelClick?: () => void;
}

const AgentForm = ({ agent, agentSearch, ...props }: Props) => {
  const [form] = Form.useForm<CreateUpdateAgent>();
  useFormErrorHandler(form, "agent.attrs", [requests.CREATE_AGENT, requests.UPDATE_AGENT]);

  const [serviceAddressEnabled, setServiceAddressEnabled] = useState<boolean>(!!agent?.serviceAddress);
  const [correspondenceAddressEnabled, setCorrespondenceAddressEnabled] = useState<boolean>(
    !!agent?.correspondenceAddress
  );

  useEffect(() => {
    if (agent) {
      form.setFieldsValue(parseAgentToCreateUpdateAgent(agent));
    }
  }, [agent, form]);

  useEffect(() => {
    if (agentSearch?.result?.data) {
      const { keyword, agentType, data } = agentSearch.result;
      if (
        (agentType === AgentType.NATURAL && keyword === form.getFieldValue("personalIdentificationNumber")) ||
        (agentType !== AgentType.NATURAL && keyword === form.getFieldValue("companyRegistrationNumber"))
      ) {
        const formData = form.getFieldsValue();
        form.setFieldsValue({
          ...parseAgentToCreateUpdateAgent(data),
          parentId: formData.parentId,
          structureIdNumber: formData.structureIdNumber,
          partnerIdNumber: formData.partnerIdNumber,
          canBeGainer: formData.canBeGainer,
          deactivated: formData.deactivated
        });

        setServiceAddressEnabled(!!data.serviceAddress);
        setCorrespondenceAddressEnabled(!!data.correspondenceAddress);
      }
    }
  }, [agentSearch?.result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAgentTypeChange = (type: AgentType): void => {
    form.setFieldsValue({ canBeGainer: type !== AgentType.NATURAL });
  };

  const handleTipperChange = (event: CheckboxChangeEvent): void => {
    if (event.target.checked) {
      form.setFieldsValue({ nbsRegistrationNumber: undefined });
    }
  };

  const handlePinOrCrnSearchOrChange = (pinOrCrn: string): void => {
    if (agentSearch) {
      const type = form.getFieldValue("type") as AgentType;
      form
        .validateFields(type === AgentType.NATURAL ? ["personalIdentificationNumber"] : ["companyRegistrationNumber"])
        .then(() => agentSearch.onSearch({ keyword: pinOrCrn, agentType: type }))
        .catch(resolveFormValidationError);
    }
  };

  const handleFormSubmit = (): void => {
    let fieldsToValidate = getAllFieldsNames(form);
    fieldsToValidate = serviceAddressEnabled
      ? fieldsToValidate
      : fieldsToValidate.filter(fieldPath => !contains(fieldPath, "serviceAddress"));
    fieldsToValidate = correspondenceAddressEnabled
      ? fieldsToValidate
      : fieldsToValidate.filter(fieldPath => !contains(fieldPath, "correspondenceAddress"));

    form
      .validateFields(fieldsToValidate)
      .then(values => {
        const processedValues = { ...values };

        processedValues.structureIdNumber = parseInt(processedValues.structureIdNumber as string);
        processedValues.bankAccountNumber = removeStringWhiteSpaces(processedValues.bankAccountNumber);
        processedValues.sectors = processedValues.sectors ? processedValues.sectors : [];
        processedValues.contacts =
          processedValues.contacts?.map(contact => ({ ...contact, marketingConsent: MarketingConsent.NOT_GRANTED })) ||
          [];
        processedValues.serviceAddress = serviceAddressEnabled ? processedValues.serviceAddress : undefined;
        processedValues.correspondenceAddress = correspondenceAddressEnabled
          ? processedValues.correspondenceAddress
          : undefined;

        if (agent) {
          props.onUpdate?.({ id: agent.id, object: processedValues });
        } else {
          props.onCreate?.(processedValues);
        }
      })
      .catch(resolveFormValidationError);
  };

  // TODO: Create new method for converting from Agent to CreateUpdateAgent in utils.ts
  const parseAgentToCreateUpdateAgent = (agent: Agent) => {
    return {
      ...agent,
      structureIdNumber: formatAgentStructureIdNumber(agent),
      parentId: agent.parent ? agent.parent.id : undefined,
      phone: formatPhoneNumber(agent.phone),
      bankAccountNumber: agent.bankAccountNumber ? formatIban(agent.bankAccountNumber) : undefined,
      contacts: agent.contacts.map(contact => ({
        ...contact,
        value: contact.type === ContactType.PHONE_NUMBER ? formatPhoneNumber(contact.value) : contact.value
      })),
      representativeIds:
        agent.type === AgentType.LEGAL ? (agent as LegalAgent).representatives.map(r => r.id) : undefined,
      profilePicture: undefined,
      parent: undefined,
      attachments: undefined,
      historyRecords: undefined,
      createdBy: undefined
    };
  };

  const colSpan = 4;

  return (
    <Form form={form} layout="vertical" name="agentUpdateForm">
      <HiddenInput name="optimisticLockVersion" />
      {agent ? (
        <HiddenInput name="type" />
      ) : (
        <Row gutter={rowGutter}>
          <Col span={6}>
            <Form.Item
              name="type"
              label={t("agent.enums.type._label")}
              rules={[validations.notNull]}
              initialValue={AgentType.NATURAL}
            >
              <Select<AgentType>
                {...selectTagsStandardProps(agentTypeTMap)}
                options={Object.keys(AgentType).map(type => ({
                  value: type,
                  label: <AgentTypeLongNameTag type={AgentType[type as keyof typeof AgentType]} />
                }))}
                onChange={handleAgentTypeChange}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={rowGutter}>
        {!agent ? (
          <Col span={colSpan}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.deactivated !== next.deactivated}>
              {({ getFieldValue }) => (
                <AgentSelect
                  formItemProps={{
                    name: "parentId",
                    label: t("agent.attrs.parentId"),
                    rules: [validations.notNull]
                  }}
                  optionsProps={{
                    filter: agent => getFieldValue("deactivated") || !agent.deactivated
                  }}
                />
              )}
            </Form.Item>
          </Col>
        ) : (
          <HiddenInput name={"parentId"} />
        )}

        <Col span={colSpan}>
          <Form.Item
            name="structureIdNumber"
            label={t("agent.attrs.structureIdNumber")}
            rules={[validations.size(1, 3), validations.numeric]}
          >
            <Input addonAfter={formatAgentShortIdNumber(agent)} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item name="partnerIdNumber" label={t("agent.attrs.partnerIdNumber")} rules={[validations.size(1, 64)]}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="canBeGainer"
            valuePropName="checked"
            rules={[validations.none]}
            className="form-item-without-label"
            initialValue={false}
          >
            <Checkbox>{t("agent.attrs.canBeGainer")}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="deactivated"
            valuePropName="checked"
            rules={[validations.none]}
            className="form-item-without-label"
            initialValue={false}
          >
            <Checkbox>{t("agent.attrs.deactivated")}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left">{t("agent.titles.basicData")}</Divider>

      <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
        {({ getFieldValue }) => {
          switch (getFieldValue("type")) {
            case AgentType.NATURAL:
              return (
                <NaturalAgentDataFormPart
                  form={form}
                  searchInProgress={agentSearch?.inProgress}
                  onTipperChange={handleTipperChange}
                  onPinSearchOrChange={agentSearch ? handlePinOrCrnSearchOrChange : undefined}
                />
              );
            case AgentType.SELF_EMPLOYED:
              return (
                <SelfEmployedAgentDataFormPart
                  form={form}
                  searchInProgress={agentSearch?.inProgress}
                  onTipperChange={handleTipperChange}
                  onCrnSearchOrChange={agentSearch ? handlePinOrCrnSearchOrChange : undefined}
                />
              );
            case AgentType.LEGAL:
              return (
                <LegalAgentDataFormPart
                  agent={agent}
                  searchInProgress={agentSearch?.inProgress}
                  onTipperChange={handleTipperChange}
                  onCrnSearchOrChange={agentSearch ? handlePinOrCrnSearchOrChange : undefined}
                />
              );
            default:
              return null;
          }
        }}
      </Form.Item>

      <Divider orientation="left">{t("agent.titles.addresses")}</Divider>

      <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
        {({ getFieldValue }) => (
          <div style={{ maxWidth: 1100 }}>
            <AgentAddressesFormPart
              form={form}
              agentType={getFieldValue("type")}
              serviceAddressEnabled={serviceAddressEnabled}
              correspondenceAddressEnabled={correspondenceAddressEnabled}
              onServiceAddressEnabledChange={setServiceAddressEnabled}
              onCorrespondenceAddressEnabledChange={setCorrespondenceAddressEnabled}
            />
          </div>
        )}
      </Form.Item>

      <Divider orientation="left">{t("agent.titles.contacts")}</Divider>

      <ContactsForm />

      <div className="margin-top-large">
        <Space>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.parentId !== next.parentId}>
            <Button type="primary" icon={<AntIcon type="save" />} onClick={handleFormSubmit}>
              {t("common.save")}
            </Button>
          </Form.Item>
          {props.onCancelClick && (
            <Button onClick={props.onCancelClick} icon={<AntIcon type="close" />}>
              {t("common.cancel")}
            </Button>
          )}
        </Space>
      </div>
    </Form>
  );
};

export default AgentForm;
