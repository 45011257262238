import { Button, Col, Divider, Popconfirm, Row, Space, Switch, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { PageSizes, rowGutter, TableSizes } from "../../../../../common/constants";
import { formatLocaleCurrencyWithNullAsZero, formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import {
  appendSearchParamsToURL,
  numberOrZero,
  paginationTableProps,
  tableStandardProps
} from "../../../../../common/utils/utils";
import type { UUID } from "../../../../../typings/global";
import NotFinishedBatchTag from "../../../batch/components/NotFinishedBatchTag";
import { CommissionsBatchStep } from "../../../batch/enums";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../../batch/paths";
import { removeBatchNameCustomSuffix } from "../../../batch/utils";
import {
  createBailAccountMovementActions,
  deleteBailAccountMovementActions,
  deleteStateBailAccountMovementsPageAction,
  filterBailAccountMovementsActions,
  updateBailAccountMovementActions
} from "../../ducks";
import { BailAccountMovement, BailAccountMovementFilterPageResult } from "../../types";
import BailAccountMovementForm from "../forms/BailAccountMovementForm";
import ManualBailAccountMovementTag from "../ManualBailAccountMovementTag";

interface Props {
  agentId: UUID;
  movementsPage: BailAccountMovementFilterPageResult;
  filterBailAccountMovements: typeof filterBailAccountMovementsActions.request;
  createBailAccountMovement?: typeof createBailAccountMovementActions.request;
  updateBailAccountMovement?: typeof updateBailAccountMovementActions.request;
  deleteBailAccountMovement?: typeof deleteBailAccountMovementActions.request;
  deleteStateBailAccountMovements: typeof deleteStateBailAccountMovementsPageAction;
  showFullBatchNameWithLink?: boolean;
  showActions: boolean;
  showCreateMovementAction: boolean;
  showFilterSwitch: boolean;
  urlSearchQuery: string;
}

const BailAccountMovementTableView = ({
  agentId,
  movementsPage,
  filterBailAccountMovements,
  createBailAccountMovement,
  updateBailAccountMovement,
  deleteBailAccountMovement,
  deleteStateBailAccountMovements,
  showFullBatchNameWithLink,
  showActions,
  showCreateMovementAction,
  showFilterSwitch,
  urlSearchQuery
}: Props) => {
  const navigate = useNavigate();

  const [bailAccountMovementFormOpen, setBailAccountMovementFormOpen] = useState<boolean>(false);
  const [bailAccountMovementToUpdate, setBailAccountMovementToUpdate] = useState<BailAccountMovement>();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    filterBailAccountMovements({
      id: agentId,
      object: {
        pageIndex: numberOrZero(urlParams.get("pageIndex")),
        pageSize: PageSizes.MEDIUM,
        onlyProcessed: true,
        onlyManual: false
      }
    });
    return () => {
      deleteStateBailAccountMovements();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBailAccountMovementsTablePageChange = (pageNumber: number): void => {
    const { pageSize, onlyProcessed, onlyManual } = movementsPage;

    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    filterBailAccountMovements({
      id: agentId,
      object: {
        pageIndex: pageNumber - 1,
        pageSize,
        onlyProcessed,
        onlyManual
      }
    });
  };

  const handleBailAccountMovementsOnlyProcessedChange = (onlyProcessed: boolean): void => {
    const { pageSize, onlyManual } = movementsPage;
    navigate(appendSearchParamsToURL({ pageIndex: undefined }), { replace: true });
    filterBailAccountMovements({
      id: agentId,
      object: { pageIndex: 0, pageSize, onlyProcessed, onlyManual }
    });
  };

  const handleBailAccountMovementsOnlyManualChange = (onlyManual: boolean): void => {
    const { pageSize, onlyProcessed } = movementsPage;
    navigate(appendSearchParamsToURL({ pageIndex: undefined }), { replace: true });
    filterBailAccountMovements({
      id: agentId,
      object: { pageIndex: 0, pageSize, onlyProcessed, onlyManual }
    });
  };

  const handleBailAccountMovementCreateClick = (): void => {
    setBailAccountMovementFormOpen(true);
  };

  const handleBailAccountMovementUpdateClick = (movement: BailAccountMovement): void => {
    setBailAccountMovementFormOpen(true);
    setBailAccountMovementToUpdate(movement);
  };

  const handleBailAccountMovementFormCancel = (): void => {
    setBailAccountMovementFormOpen(false);
    setBailAccountMovementToUpdate(undefined);
  };

  const columns: ColumnsType<BailAccountMovement> = [
    {
      key: "commissionsBatch",
      title: t("commissions.bailAccount.attrs.movements.commissionsBatch"),
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, record) =>
        record.commissionsBatch ? (
          showFullBatchNameWithLink ? (
            <Ellipsis tooltip={record.commissionsBatch.name}>
              <Link
                to={generatePath(COMMISSIONS_BATCH_ROUTE_PATHS.detail.to, { id: record.commissionsBatch.id })}
                target="_blank"
              >
                {record.commissionsBatch.name}
              </Link>
            </Ellipsis>
          ) : (
            <Ellipsis>{removeBatchNameCustomSuffix(record.commissionsBatch)}</Ellipsis>
          )
        ) : (
          <Ellipsis className="sub-header-info dashed">{t("commissions.bailAccount.helpers.noBatch")}</Ellipsis>
        )
    },
    {
      key: "batchStage",
      width: 130,
      render: (_, record) =>
        record.commissionsBatch &&
        record.commissionsBatch.step !== CommissionsBatchStep.FINISH && <NotFinishedBatchTag />
    },
    {
      key: "createdAt",
      title: t("common.createdAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "manual",
      width: 120,
      render: (_, record) => <ManualBailAccountMovementTag movement={record} />
    },
    {
      key: "amount",
      title: t("common.amount"),
      align: "right",
      fixed: "right",
      width: 110,
      render: (_, record) => formatLocaleCurrencyWithNullAsZero(record.amount)
    }
  ];

  if (showActions) {
    columns.push({
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) =>
        showActions &&
        record.manual &&
        !record.commissionsBatch && (
          <>
            <ActionTextIcon
              icon="edit"
              color="blue"
              text={t("common.edit")}
              onClick={() => handleBailAccountMovementUpdateClick?.(record)}
            />

            <Divider type="vertical" />

            <Popconfirm
              title={t("commissions.bailAccount.helpers.deleteMovementConfirm")}
              icon={<PopconfirmDeleteIcon />}
              okText={t("common.yes")}
              cancelText={t("common.no")}
              okType="danger"
              onConfirm={() => deleteBailAccountMovement?.({ id1: agentId, id2: record.id })}
            >
              <span>
                <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
              </span>
            </Popconfirm>
          </>
        )
    });
  }

  return (
    <>
      <Row gutter={rowGutter} className="margin-bottom-small">
        <Col span={12}>
          <Space>
            <span>
              {t("commissions.bailAccount.helpers.currentBalance")}:{" "}
              <b>{formatLocaleCurrencyWithNullAsZero(movementsPage.amountsSum)}</b>
            </span>
            {showActions && showCreateMovementAction && (
              <Button
                type="link"
                icon={<AntIcon type="plus-circle" />}
                size="small"
                onClick={handleBailAccountMovementCreateClick}
              >
                {t("commissions.bailAccount.actions.createMovement")}
              </Button>
            )}
          </Space>
        </Col>

        {showFilterSwitch && (
          <Col span={12} className="right-align">
            <div>
              <Switch
                size="small"
                defaultChecked={true}
                checkedChildren={<AntIcon type="check" />}
                unCheckedChildren={<AntIcon type="close" />}
                onChange={handleBailAccountMovementsOnlyProcessedChange}
              />
              <span className="margin-left-tiny">{t("commissions.bailAccount.helpers.showOnlyProcessed")}</span>
            </div>
            <div style={{ paddingRight: "9px" }}>
              <Switch
                size="small"
                defaultChecked={false}
                checkedChildren={<AntIcon type="check" />}
                unCheckedChildren={<AntIcon type="close" />}
                onChange={handleBailAccountMovementsOnlyManualChange}
              />
              <span className="margin-left-tiny">{t("commissions.bailAccount.helpers.showOnlyManual")}</span>
            </div>
          </Col>
        )}
      </Row>

      <Row gutter={rowGutter}>
        <Col span={24}>
          <Table<BailAccountMovement>
            {...tableStandardProps()}
            columns={columns}
            scroll={{ x: TableSizes.MEDIUM }}
            dataSource={movementsPage.pageData}
            pagination={{
              ...paginationTableProps,
              position: ["bottomRight"],
              current: movementsPage.pageIndex + 1,
              pageSize: movementsPage.pageSize,
              total: movementsPage.totalElementsCount,
              onChange: handleBailAccountMovementsTablePageChange
            }}
          />
        </Col>
      </Row>

      <BailAccountMovementForm
        movement={bailAccountMovementToUpdate}
        agentId={agentId}
        open={bailAccountMovementFormOpen}
        onCreate={createBailAccountMovement}
        onUpdate={updateBailAccountMovement}
        onFormCancel={handleBailAccountMovementFormCancel}
      />
    </>
  );
};

export default BailAccountMovementTableView;
