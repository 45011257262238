import { Button, Col, Divider, Form, Row, Select, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import dayjs, { Dayjs } from "dayjs";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import ContactsForm from "../../../../common/modules/contact/ContactsForm";
import { ContactType, MarketingConsent } from "../../../../common/modules/enums";
import { Contact } from "../../../../common/modules/types";
import { formatPhoneNumber } from "../../../../common/utils/formatUtils";
import {
  dateToIsoDateString,
  getAllFieldsNames,
  resolveFormValidationError,
  selectTagsStandardProps
} from "../../../../common/utils/formUtils";
import messageUtils from "../../../../common/utils/messageUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { ClientType, clientTypeTMap } from "../../enums";
import {
  Client,
  ClientSearchProps,
  CreateUpdateClient,
  CreateUpdateLegalClient,
  LegalClient,
  NaturalClient
} from "../../types";
import { processClientFormValues } from "../../utils";
import ClientTypeLongNameTag from "../tags/ClientTypeLongNameTag";
import ClientAddressesFormPart from "./parts/ClientAddressesFormPart";
import ClientRepresentativesFormPart from "./parts/ClientRepresentativesFormPart";
import LegalClientDataFormPart from "./parts/LegalClientDataFormPart";
import NaturalClientDataFormPart from "./parts/NaturalClientDataFormPart";
import SelfEmployedClientDataFormPart from "./parts/SelfEmployedClientDataFormPart";

interface Props {
  form: FormInstance<CreateUpdateClient>;
  client?: Client;
  initialClientType?: ClientType;
  excludedClientTypes?: ClientType[];
  disablePinInput?: boolean;
  disableCrnInput?: boolean;
  requireIdCardNumber?: boolean;
  viewType: "form" | "drawer";
  clientSearch?: ClientSearchProps;
  onFormSubmit: (client: CreateUpdateClient) => void;
  onCancelClick?: () => void;
}

const ClientFormBody = ({ form, client, clientSearch, ...props }: Props) => {
  const enabledClientTypes: ClientType[] = props.excludedClientTypes
    ? Object.values(ClientType).filter(type => !props.excludedClientTypes?.includes(type))
    : Object.values(ClientType);

  useEffect(() => {
    if (client) {
      form.setFieldsValue(parseClientToCreateUpdateClient(client));
    } else {
      form.resetFields();
    }
  }, [client, form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientSearch?.result?.data) {
      const { keyword, clientType, data } = clientSearch.result;
      if (keyword === form.getFieldValue("companyRegistrationNumber") && clientType === form.getFieldValue("type")) {
        form.setFieldsValue(parseClientToCreateUpdateClient(data));
        if (data.id) {
          form.setFields([{ name: "companyRegistrationNumber", errors: [t("validation.unique")] }]);
        }
      }
    }
  }, [clientSearch?.result]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClientTypeChange = (): void => {
    if (form.getFieldValue(["representatives"])?.length > 0) {
      form.setFieldsValue({ representatives: [] } as Record<string, any>);
    }
  };

  const handleCrnSearchOrChange = (crn: string): void => {
    const type = form.getFieldValue("type") as ClientType;
    if (clientSearch && (type === ClientType.SELF_EMPLOYED || type === ClientType.LEGAL)) {
      form
        .validateFields(["companyRegistrationNumber"])
        .then(() => clientSearch.onSearch({ keyword: crn, clientType: type }))
        .catch(resolveFormValidationError);
    }
  };

  const handleMarketingConsentChange = (consent: MarketingConsent, index: number) => {
    const contacts = [...form.getFieldValue("contacts")];
    contacts[index] = {
      ...contacts[index],
      marketingConsentStartDate:
        consent === MarketingConsent.GRANTED ? contacts[index]?.marketingConsentStartDate : undefined,
      marketingConsentEndDate:
        consent === MarketingConsent.GRANTED ? contacts[index]?.marketingConsentEndDate : undefined
    };

    form.setFieldsValue({ contacts: contacts as Contact[] });
  };

  const handleMarketingConsentStartDateChange = (startDate: Dayjs, index: number) => {
    const contacts = [...form.getFieldValue("contacts")];
    contacts[index] = {
      ...contacts[index],
      marketingConsentEndDate: dateToIsoDateString(dayjs(startDate).add(5, "year"))
    };

    form.setFieldsValue({ contacts: contacts as Contact[] });
  };

  const handleFormSubmit = (): void => {
    if (props.viewType === "drawer") {
      props.onFormSubmit(processClientFormValues(form.getFieldsValue()));
    } else {
      let fieldsToValidate = getAllFieldsNames(form);

      const notGrantedMarketingConsentIndices = fieldsToValidate
        .filter(
          fieldPath =>
            // @ts-ignore ts-problem
            fieldPath.includes("marketingConsent") && form.getFieldValue(fieldPath) !== MarketingConsent.GRANTED
        )
        .map(fieldPath => fieldPath[1]);

      fieldsToValidate = fieldsToValidate.filter(
        fieldPath =>
          (!fieldPath.includes("marketingConsentStartDate") && !fieldPath.includes("marketingConsentEndDate")) ||
          !notGrantedMarketingConsentIndices.includes(fieldPath[1])
      );

      fieldsToValidate = form.getFieldValue("correspondenceAddressEnabled")
        ? fieldsToValidate
        : fieldsToValidate.filter(fieldPath => !fieldPath.includes("correspondenceAddress"));

      form
        .validateFields(fieldsToValidate)
        .then(values => {
          const processedValues = processClientFormValues(values);

          if (processedValues.type === ClientType.LEGAL) {
            const representatives = (values as CreateUpdateLegalClient).representatives || [];
            if (representatives.some(r => !r.representativeId)) {
              messageUtils.errorNotification({
                message: t("common.error"),
                description: t("client.validations.representativesError")
              });
              return;
            }
            (processedValues as CreateUpdateLegalClient).representatives = representatives.map(r => ({
              ...r,
              representativePin: undefined
            }));
          }

          props.onFormSubmit(processedValues);
        })
        .catch(resolveFormValidationError);
    }
  };

  const parseClientToCreateUpdateClient = (client: Client): CreateUpdateClient => {
    const values = {
      ...client,
      contacts: client.contacts.map(contact => ({
        ...contact,
        value: contact.type === ContactType.PHONE_NUMBER ? formatPhoneNumber(contact.value) : contact.value,
        marketingConsentStartDate: contact.marketingConsentStartDate,
        marketingConsentEndDate: contact.marketingConsentEndDate
      })),
      correspondenceAddressEnabled: !!client.correspondenceAddress,
      createdAs: undefined,
      attachments: undefined,
      historyRecords: undefined
    } as unknown as CreateUpdateClient;

    if (values.type === ClientType.LEGAL) {
      (values as CreateUpdateLegalClient).representatives =
        (client as LegalClient).representatives?.map(r => ({
          id: r.id,
          optimisticLockVersion: r.optimisticLockVersion,
          function: r.function,
          representativeId: r.representative.id,
          representativePin: r.representative.identifier
        })) ?? [];
    }

    return values as CreateUpdateClient;
  };

  const colSpan = props.viewType === "form" ? 4 : 6;

  return (
    <>
      <HiddenInput name="optimisticLockVersion" />
      {client ? (
        <HiddenInput name="type" />
      ) : (
        <Row gutter={rowGutter}>
          <Col span={7}>
            <Form.Item
              name="type"
              label={t("client.enums.type._label")}
              rules={[validations.notNull]}
              initialValue={props.initialClientType || enabledClientTypes[0]}
            >
              <Select
                {...selectTagsStandardProps(clientTypeTMap)}
                disabled={!!props.initialClientType}
                options={enabledClientTypes.map(type => ({
                  value: type,
                  label: <ClientTypeLongNameTag type={ClientType[type]} />
                }))}
                onChange={handleClientTypeChange}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Divider orientation="left">{t("client.titles.basicData")}</Divider>

      <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
        {({ getFieldValue }) => {
          switch (getFieldValue("type")) {
            case ClientType.NATURAL:
              return (
                <NaturalClientDataFormPart
                  form={form}
                  colSpan={colSpan}
                  disablePinInput={props.disablePinInput}
                  requireIdCardNumber={props.requireIdCardNumber}
                  previousIdCardNumber={(client as NaturalClient)?.previousIdentityCardNumber}
                />
              );
            case ClientType.SELF_EMPLOYED:
              return (
                <SelfEmployedClientDataFormPart
                  form={form}
                  colSpan={colSpan}
                  disableCrnInput={props.disableCrnInput}
                  requireIdCardNumber={props.requireIdCardNumber}
                  previousIdCardNumber={(client as NaturalClient)?.previousIdentityCardNumber}
                  searchInProgress={clientSearch?.inProgress}
                  onCrnSearchOrChange={clientSearch ? handleCrnSearchOrChange : undefined}
                />
              );
            case ClientType.LEGAL:
              return (
                <>
                  <LegalClientDataFormPart
                    colSpan={colSpan}
                    disableCrnInput={props.disableCrnInput}
                    searchInProgress={clientSearch?.inProgress}
                    onCrnSearchOrChange={clientSearch ? handleCrnSearchOrChange : undefined}
                  />

                  {props.viewType === "form" && (
                    <>
                      <Divider orientation="left">{t("client.titles.representatives")}</Divider>
                      <ClientRepresentativesFormPart
                        form={form}
                        initialClient={client as LegalClient}
                        colSpan={colSpan}
                      />
                    </>
                  )}
                </>
              );
            default:
              return null;
          }
        }}
      </Form.Item>

      <Divider orientation="left">{t("client.titles.addresses")}</Divider>

      <Form.Item noStyle shouldUpdate={(prev, next) => prev.type !== next.type}>
        {({ getFieldValue }) => (
          <div style={props.viewType === "form" ? { maxWidth: 1100 } : undefined}>
            <ClientAddressesFormPart form={form} clientType={getFieldValue("type")} />
          </div>
        )}
      </Form.Item>

      <Divider orientation="left">{t("client.titles.contacts")}</Divider>

      <ContactsForm
        marketingConsentApplicable
        onMarketingConsentChange={handleMarketingConsentChange}
        onMarketingConsentStartDateChange={handleMarketingConsentStartDateChange}
      />

      <Space className="margin-top-large">
        <Button
          type="primary"
          icon={props.viewType === "form" ? <AntIcon type="save" /> : <AntIcon type="check" />}
          onClick={handleFormSubmit}
        >
          {props.viewType === "form" ? t("common.save") : t("common.submit")}
        </Button>

        {props.onCancelClick && (
          <Button type="default" onClick={props.onCancelClick} icon={<AntIcon type="close" />}>
            {t("common.cancel")}
          </Button>
        )}
      </Space>
    </>
  );
};

export default ClientFormBody;
