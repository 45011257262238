import { Button, Col, Popconfirm, Row, Tooltip } from "antd";
import { Link } from "react-router-dom";
import t from "../../../app/i18n";
import { StorageFile } from "../../modules/types";
import { LinkTargetType } from "../../types";
import AntIcon from "../icons/AntIcon";
import FileTypeIcon from "../icons/FileTypeIcon";
import PopconfirmDeleteIcon from "../icons/PopconfirmDeleteIcon";
import Ellipsis from "./Ellipsis";
import styles from "./FileThumbnailCardView.module.scss";

interface Props {
  file: StorageFile;
  orientation: "horizontal" | "vertical";
  iconSize: number;
  linkPath: string;
  linkTarget: LinkTargetType;
  onDelete?: () => void;
}

const FileThumbnailCardView = ({ file, orientation, iconSize, linkPath, linkTarget, onDelete }: Props) => (
  <Tooltip title={file.filename} placement="bottom">
    <div className={styles.fileThumbnail}>
      {onDelete && (
        <Popconfirm
          title={t("common.deleteAttachmentConfirm")}
          icon={<PopconfirmDeleteIcon />}
          okText={t("common.yes")}
          cancelText={t("common.no")}
          okType="danger"
          onConfirm={onDelete}
        >
          <Button
            size="small"
            type="dashed"
            icon={<AntIcon type="delete" />}
            danger
            className={styles.fileThumbnailDeleteBtn}
          />
        </Popconfirm>
      )}
      {linkPath ? (
        <Link to={linkPath} target={linkTarget} className={styles.fileThumbnailLink}>
          <Row
            gutter={0}
            align="middle"
            style={{ margin: 8, flexDirection: orientation === "horizontal" ? "column" : "row" }}
          >
            <Col flex={`${iconSize + 8}px`}>
              <FileTypeIcon
                contentType={file.contentType}
                className="margin-right-tiny"
                style={{ fontSize: `${iconSize}px` }}
              />
            </Col>
            <Col>
              <Ellipsis shouldRenderTooltip={false}>{file.filename}</Ellipsis>
            </Col>
          </Row>
        </Link>
      ) : (
        <Row
          gutter={0}
          align="middle"
          style={{ margin: 8, flexDirection: orientation === "horizontal" ? "column" : "row" }}
        >
          <Col flex={`${iconSize + 8}px`}>
            <FileTypeIcon
              contentType={file.contentType}
              className="margin-right-tiny"
              style={{ fontSize: `${iconSize}px` }}
            />
          </Col>
          <Col>
            <Ellipsis shouldRenderTooltip={false}>{file.filename}</Ellipsis>
          </Col>
        </Row>
      )}
    </div>
  </Tooltip>
);

export default FileThumbnailCardView;
