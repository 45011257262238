import { Card, Col, Form, Radio, Row } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import t from "../../../app/i18n";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import { rowGutter } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { RootState } from "../../../common/types";
import { hasPermission } from "../../../common/utils/utils";
import { selectPermissions } from "../../auth/ducks";
import DepositContractForm from "../components/forms/deposit/DepositContractForm";
import GenericContractForm from "../components/forms/generic/GenericContractForm";
import InsuranceContractForm from "../components/forms/insurance/InsuranceContractForm";
import InvestmentContractForm from "../components/forms/investment/InvestmentContractForm";
import LoanContractForm from "../components/forms/loan/LoanContractForm";
import SecondPillarContractForm from "../components/forms/secondpillar/SecondPillarContractForm";
import ThirdPillarContractForm from "../components/forms/thirdpillar/ThirdPillarContractForm";
import { createContractActions } from "../ducks";
import { ContractType } from "../enums";

export const ContractCreateContainer = () => {
  const permissions = useSelector<RootState, Permission[]>(selectPermissions);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          createContract: createContractActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  const allowedContractTypes = useMemo(() => {
    const contractTypes: ContractType[] = [];

    if (hasPermission(permissions, Permission.INSURANCE_CREATE)) {
      contractTypes.push(ContractType.INSURANCE_CONTRACT);
    }
    if (hasPermission(permissions, Permission.LOAN_CREATE)) {
      contractTypes.push(ContractType.LOAN_CONTRACT);
    }
    if (hasPermission(permissions, Permission.INVESTMENT_CREATE)) {
      contractTypes.push(ContractType.INVESTMENT_CONTRACT);
    }
    if (hasPermission(permissions, Permission.DEPOSIT_CREATE)) {
      contractTypes.push(ContractType.DEPOSIT_CONTRACT);
    }
    if (hasPermission(permissions, Permission.SECOND_PILLAR_CREATE)) {
      contractTypes.push(ContractType.SECOND_PILLAR_CONTRACT);
    }
    if (hasPermission(permissions, Permission.THIRD_PILLAR_CREATE)) {
      contractTypes.push(ContractType.THIRD_PILLAR_CONTRACT);
    }
    if (hasPermission(permissions, Permission.GENERIC_CREATE)) {
      contractTypes.push(ContractType.GENERIC_CONTRACT);
    }

    return contractTypes;
  }, [permissions]);

  const [contractType, setContractType] = useState<ContractType | undefined>(
    allowedContractTypes.length === 1 ? allowedContractTypes[0] : undefined
  );

  let content: React.ReactNode;
  switch (contractType) {
    case ContractType.INSURANCE_CONTRACT:
      content = <InsuranceContractForm onCreateFormSubmit={actions.createContract} />;
      break;
    case ContractType.LOAN_CONTRACT:
      content = <LoanContractForm onCreateFormSubmit={actions.createContract} />;
      break;
    case ContractType.INVESTMENT_CONTRACT:
      content = <InvestmentContractForm onCreateFormSubmit={actions.createContract} />;
      break;
    case ContractType.DEPOSIT_CONTRACT:
      content = <DepositContractForm onCreateFormSubmit={actions.createContract} />;
      break;
    case ContractType.SECOND_PILLAR_CONTRACT:
      content = <SecondPillarContractForm onCreateFormSubmit={actions.createContract} />;
      break;
    case ContractType.THIRD_PILLAR_CONTRACT:
      content = <ThirdPillarContractForm onCreateFormSubmit={actions.createContract} />;
      break;
    case ContractType.GENERIC_CONTRACT:
      content = <GenericContractForm onCreateFormSubmit={actions.createContract} />;
      break;
    default:
      content = (
        <div className="center-align sub-header-info dashed">{t("contract.helpers.noContractTypeSelected")}</div>
      );
      break;
  }

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={
          <BackNavigationArrow>
            <h2>{t("contract.titles.createContract")}</h2>
          </BackNavigationArrow>
        }
      >
        <Row gutter={rowGutter} justify="center">
          <Col span={24} className="center-align">
            <Form.Item className="no-bottom-space">
              <Radio.Group
                value={contractType}
                size="large"
                buttonStyle="solid"
                className="radio-group-icon"
                onChange={e => setContractType(e.target.value)}
              >
                {allowedContractTypes.map(type => (
                  <Radio.Button value={type} key={type} style={{ width: 140 }}>
                    <span style={{ fontSize: 16, fontWeight: "bold", lineHeight: "20px" }}>
                      {t("contract.actions.createContractType." + type)}
                    </span>
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      {content}
    </ContentWrapper>
  );
};
