import { Card, Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { RootState, SearchPageResult } from "../../../../common/types";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { TopAgentType } from "../../../agent/enums";
import { selectUserAccount } from "../../../auth/ducks";
import { UserAccount } from "../../../user/types";
import { deleteProductGroupActions } from "../../ducks";
import { ProductGroup } from "../../types";

interface Props {
  productGroupsPage: SearchPageResult<ProductGroup>;
  onPageChange: (pageNumber: number) => void;
  onUpdateClick: (group: ProductGroup) => void;
  onDelete: typeof deleteProductGroupActions.request;
}

const ProductGroupTableView = (props: Props) => {
  const { t } = useTranslation();
  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);

  const getColumns = () => {
    const columns: ColumnsType<ProductGroup> = [
      {
        key: "name",
        title: t("product.attrs.name"),
        ellipsis: { showTitle: false },
        render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
      }
    ];

    if (userAccount?.agent?.topAgentType === TopAgentType.PLUS) {
      columns.push({
        key: "name",
        title: t("product.enums.productGroupCategory._label"),
        render: (_, record) =>
          record.category ? (
            <Ellipsis>{t(`product.enums.productGroupCategory.${record.category}`)}</Ellipsis>
          ) : undefined
      });
    }

    columns.push({
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon
            icon="edit"
            color="blue"
            text={t("common.edit")}
            onClick={() => props.onUpdateClick(record)}
          />

          <Divider type="vertical" />

          <Popconfirm
            title={t("product.helpers.productGroupDeleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => props.onDelete({ id: record.id })}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </>
      )
    });

    return columns;
  };

  return (
    <Card className="card-box">
      <Table<ProductGroup>
        {...tableStandardProps()}
        columns={getColumns()}
        scroll={{ x: TableSizes.SMALL }}
        dataSource={props.productGroupsPage.pageData}
        pagination={{
          ...paginationTableProps,
          current: props.productGroupsPage.pageIndex + 1,
          pageSize: props.productGroupsPage.pageSize,
          total: props.productGroupsPage.totalElementsCount,
          onChange: props.onPageChange
        }}
      />
    </Card>
  );
};

export default ProductGroupTableView;
