import { Card, Col, DatePicker, Form, InputNumber, Row, Select } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import { Dayjs } from "dayjs";
import ReactQuill from "react-quill-new";
import t from "../../../../../../app/i18n";
import InputAddon from "../../../../../../common/components/form/addons/InputAddon";
import { rowGutter } from "../../../../../../common/constants";
import {
  datePickerClearableProps,
  datePickerFormItemProps,
  datePickerStandardProps,
  dateToIsoDateString,
  disableDatePickerOutOfMaxDate,
  disableDatePickerOutOfMaxDateIncluded,
  disableDatePickerOutOfMinDate,
  disableDatePickerOutOfMinDateIncluded,
  inputNumberDecimalStandardProps,
  quillEditorStandardProps,
  selectStandardProps
} from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { LoanRateType } from "../../../../enums";
import { CreateUpdateContractGainerRecord, CreateUpdateLoanContract } from "../../../../types";
import { calculateContactClientDate, calculateContractStatus } from "../../../../utils";
import ContractStatusTag from "../../../ContractStatusTag";

interface Props {
  form: FormInstance<CreateUpdateLoanContract>;
}

const LoanContractFormDataSection = ({ form }: Props) => {
  const handleSignDateChange = (
    signDate: string,
    loanMaturityDate: string,
    cancellationDate: string,
    gainerRecords: CreateUpdateContractGainerRecord[]
  ): void => {
    handleStatusDefiningDateChange(signDate, loanMaturityDate, cancellationDate);
    const records = [...gainerRecords];

    if (records[0]) {
      records[0] = { ...records[0], startDate: signDate };
    }

    form.setFieldsValue({ gainerRecords: records });
  };

  const handleStatusDefiningDateChange = (
    signDate: string,
    loanMaturityDate: string,
    cancellationDate: string
  ): void => {
    form.setFieldsValue({
      status: calculateContractStatus({
        startDate: signDate,
        endDate: loanMaturityDate,
        cancellationDate: cancellationDate,
        transferredToOtherBrokerDate: undefined
      })
    });
  };

  const handleRateTypeChange = (rateType: LoanRateType): void => {
    if (rateType === LoanRateType.VARIABLE) {
      form.setFieldsValue({ fixationAnniversaryDate: undefined, contactClientDate: undefined });
    }
  };

  const handleFixationAnniversaryDateChange = (fixationAnniversaryDate: Dayjs | null): void => {
    if (fixationAnniversaryDate) {
      form.setFieldsValue({ contactClientDate: calculateContactClientDate(fixationAnniversaryDate) });
    }
  };

  const colSpan = 4;

  const status = Form.useWatch("status", form);

  const applicationSignDate = Form.useWatch("applicationSignDate", form);
  const mediationReportSignDateRules: Rule[] = applicationSignDate
    ? [validations.notNull]
    : [validations.notNull, validations.notAfter(applicationSignDate, t("contract.attrs.applicationSignDate"))];

  const signDate = Form.useWatch("signDate", form);

  const applicationSignDateRules = signDate
    ? [validations.notNull]
    : [validations.notNull, validations.notAfter(signDate, t("contract.attrs.signDate"))];

  const maturityDateRules = signDate
    ? [validations.notNull]
    : [validations.notNull, validations.notSameOrBefore(signDate, t("contract.attrs.signDate"))];

  const cancellationDate = Form.useWatch("cancellationDate", form);

  const loanMaturityDate = Form.useWatch("loanMaturityDate", form);
  const cancellationDateRules = [];
  if (signDate) {
    cancellationDateRules.push(validations.notBefore(signDate, t("contract.attrs.signDate")));
  }
  if (loanMaturityDate) {
    cancellationDateRules.push(validations.notSameOrAfter(loanMaturityDate, t("contract.attrs.loanMaturityDate")));
  }

  const gainerRecords = Form.useWatch("gainerRecords", form);

  const approvedAmount = Form.useWatch("approvedAmount", form);

  const fixationAnniversaryDateRules: Rule[] = [validations.notNull];
  if (signDate) {
    fixationAnniversaryDateRules.push(validations.notSameOrBefore(signDate, t("contract.attrs.signDate")));
  }
  if (loanMaturityDate) {
    fixationAnniversaryDateRules.push(validations.notAfter(loanMaturityDate, t("contract.attrs.loanMaturityDate")));
  }

  return (
    <Card
      type="inner"
      className="card-box card-box--inner-extra"
      title={t("contract.sections.contractData")}
      extra={status ? <ContractStatusTag status={status} /> : undefined}
    >
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="mediationReportSignDate"
            label={t("contract.attrs.mediationReportSignDate")}
            rules={mediationReportSignDateRules}
            {...datePickerFormItemProps}
          >
            <DatePicker
              {...datePickerStandardProps}
              disabledDate={current =>
                applicationSignDate ? disableDatePickerOutOfMaxDate(current, applicationSignDate) : false
              }
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="applicationSignDate"
            label={t("contract.attrs.applicationSignDate")}
            rules={applicationSignDateRules}
            {...datePickerFormItemProps}
          >
            <DatePicker
              {...datePickerStandardProps}
              disabledDate={current => (signDate ? disableDatePickerOutOfMaxDate(current, signDate) : false)}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="signDate"
            label={t("contract.attrs.signDate")}
            rules={[validations.notNull]}
            {...datePickerFormItemProps}
          >
            <DatePicker
              {...datePickerStandardProps}
              onChange={value =>
                handleSignDateChange(dateToIsoDateString(value), loanMaturityDate, cancellationDate, gainerRecords)
              }
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="loanMaturityDate"
            label={t("contract.attrs.loanMaturityDate")}
            rules={maturityDateRules}
            {...datePickerFormItemProps}
          >
            <DatePicker
              {...datePickerStandardProps}
              disabledDate={current => (signDate ? disableDatePickerOutOfMinDateIncluded(current, signDate) : false)}
              onChange={value => handleStatusDefiningDateChange(signDate, dateToIsoDateString(value), cancellationDate)}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="cancellationDate"
            label={t("contract.attrs.cancellationDate")}
            rules={cancellationDateRules.length > 0 ? cancellationDateRules : [validations.none]}
            {...datePickerFormItemProps}
          >
            <DatePicker
              {...datePickerClearableProps}
              disabledDate={current =>
                (!!signDate && disableDatePickerOutOfMinDate(current, signDate)) ||
                (!!loanMaturityDate && disableDatePickerOutOfMaxDateIncluded(current, loanMaturityDate))
              }
              onChange={value => handleStatusDefiningDateChange(signDate, loanMaturityDate, dateToIsoDateString(value))}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="ltvRatio"
            label={t("contract.attrs.ltvRatio")}
            rules={[validations.minNumber(0.01), validations.maxNumber(100)]}
          >
            <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="percentage" />} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="approvedAmount"
            label={t("contract.attrs.approvedAmount")}
            rules={[validations.notNull, validations.minNumber(0.01)]}
          >
            <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="monthlyPaymentAmount"
            label={t("contract.attrs.monthlyPaymentAmount")}
            rules={[
              validations.notNull,
              validations.minNumber(0.01),
              validations.maxNumber(approvedAmount, t("contract.attrs.approvedAmount"))
            ]}
          >
            <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="interestRate"
            label={t("contract.attrs.interestRate")}
            rules={[validations.notNull, validations.minNumber(0.01), validations.maxNumber(100)]}
          >
            <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="percentage" />} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item name="rateType" label={t("contract.enums.loanRateType._label")} rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={Object.keys(LoanRateType).map(type => ({
                value: type,
                label: t("contract.enums.loanRateType." + type)
              }))}
              onChange={handleRateTypeChange}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="fixationAnniversaryDate"
            label={t("contract.attrs.fixationAnniversaryDate")}
            rules={fixationAnniversaryDateRules}
            {...datePickerFormItemProps}
          >
            <DatePicker
              {...datePickerStandardProps}
              disabledDate={current =>
                (!!signDate && disableDatePickerOutOfMinDateIncluded(current, signDate)) ||
                (!!loanMaturityDate && disableDatePickerOutOfMaxDate(current, loanMaturityDate))
              }
              onChange={handleFixationAnniversaryDateChange}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="contactClientDate"
            label={t("contract.attrs.contactClientDate")}
            rules={[validations.notNull]}
            {...datePickerFormItemProps}
          >
            <DatePicker {...datePickerStandardProps} disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name="note"
            label={t("contract.attrs.note")}
            rules={[validations.size(1, 8192)]}
            initialValue={undefined}
          >
            <ReactQuill {...quillEditorStandardProps} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default LoanContractFormDataSection;
