import { DatePickerProps, GetProp, TimeRangePickerProps } from "antd";
import { FormItemProps } from "antd/es/form/FormItem";
import { PickerProps } from "antd/lib/date-picker/generatePicker";
import { FormInstance } from "antd/lib/form";
import { InputNumberProps } from "antd/lib/input-number";
import { SelectProps } from "antd/lib/select";
import { SortOrder } from "antd/lib/table/interface";
import dayjs, { Dayjs } from "dayjs";
import { flatten } from "flat";
import { AsYouType } from "libphonenumber-js";
import at from "lodash/at";
import has from "lodash/has";
import { FieldData, NamePath, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { DefaultOptionType } from "rc-select/lib/Select";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import latinise from "voca/latinise";
import t, { DEFAULT_LOCALE } from "../../app/i18n";
import { deleteStateValidationErrorResponseAction, selectAnyValidationErrorResponse } from "../../modules/ducks";
import { InstitutionWithSettings, ProductGroupWithProducts } from "../../modules/enumerations/types";
import { InstitutionType } from "../../modules/institution/enums";
import { ValidationErrorResponse } from "../../modules/types";
import type { UUID } from "../../typings/global";
import type { ApiRequest } from "../api/ApiRequestAdapter";
import { OrderDirection } from "../enums";
import type { FieldConstraintViolation, RootState } from "../types";
import { formatLicensePlate } from "./formatUtils";
import { tValidationMessage } from "./translationUtils";
import { ALL_WHITE_SPACES_PATTERN, isLocalhostDevMode, isNotEmptyArray, parseBirthDateFromPin } from "./utils";
import { regexPatterns, validationFunctions } from "./validationUtils";

const ISO_8601_DATE_FORMAT = "YYYY-MM-DD";

const NON_DIGIT_CHARACTERS_REGEX = new RegExp("[^\\d]", "g");
const EVERY_FOURTH_CHAR_PATTERN = new RegExp(/(.{4})/, "g");
const EVERY_THIRD_CHAR_PATTERN = new RegExp(/(.{3})/, "g");
const BEGINNING_END_WHITE_SPACES_PATTER = new RegExp(/(^\s+|\s+$)/, "g");

export const useFormErrorHandler = (
  form: FormInstance,
  translationRootPath: string,
  requests: ApiRequest[]
): ValidationErrorResponse | undefined => {
  const dispatch = useDispatch();
  const { onErrorResponseDelete } = useMemo(
    () =>
      bindActionCreators(
        {
          onErrorResponseDelete: deleteStateValidationErrorResponseAction
        },
        dispatch
      ),
    [dispatch]
  );

  const errorResponse = useSelector<RootState, ValidationErrorResponse | undefined>(state =>
    selectAnyValidationErrorResponse(state, requests)
  );

  useEffect(() => {
    if (errorResponse) {
      setErrorsToForm(form, translationRootPath, errorResponse.violations);
      onErrorResponseDelete();
    }
  }, [form, translationRootPath, errorResponse, onErrorResponseDelete]);

  return errorResponse;
};

export const setErrorsToForm = (
  form: FormInstance,
  translationRootPath: string,
  violations?: FieldConstraintViolation[]
): void => {
  if (violations && isNotEmptyArray(violations)) {
    const fieldsValues = form.getFieldsValue();
    const errorFields: FieldData[] = [];

    violations.forEach((violation: FieldConstraintViolation): void => {
      const fieldValue = at(fieldsValues, violation.fieldPath)[0];
      if (
        has(fieldsValues, violation.fieldPath) &&
        (fieldValue instanceof dayjs ||
          Array.isArray(fieldValue) ||
          typeof fieldValue !== "object" ||
          fieldValue === null ||
          fieldValue === undefined)
      ) {
        errorFields.push({
          name: fieldPathToNamePath(violation.fieldPath),
          errors: violation.errors.map<string>(errorMessage => tValidationMessage(translationRootPath, errorMessage))
        });
      }
    });

    if (isNotEmptyArray(errorFields)) {
      form.setFields(errorFields);
    }
  }
};

export const fieldPathToNamePath = (fieldPath: string): NamePath => {
  return fieldPath
    .replaceAll("[", ".")
    .replaceAll("]", "")
    .split(".")
    .map(token => (regexPatterns.numberRegex.test(token) ? parseInt(token) : token));
};

export const getAllFieldsNames = (form: FormInstance): (string | number)[][] => {
  return Object.keys(
    flatten(
      JSON.parse(JSON.stringify(form.getFieldsValue(), (_, value) => (typeof value === "undefined" ? null : value)))
    )
  ).map(key => key.split(".").map(token => (regexPatterns.numberRegex.test(token) ? parseInt(token) : token)));
};

export const resolveFormValidationError = (errorInfo: ValidateErrorEntity): void => {
  if (isLocalhostDevMode()) {
    console.warn("Form validation error caught.", errorInfo);
  }
};

export const toDate = (input?: string | Dayjs): Dayjs | undefined => {
  if (!input) {
    return;
  }

  const date = dayjs(input);
  return date.isValid() ? date : undefined;
};

export const fromNow = (input: string) => {
  return dayjs(input).fromNow();
};

export const toDateArray = (inputs: string[]): Dayjs[] | undefined => {
  if (!inputs || inputs.length === 0 || inputs.filter(input => !!input).length === 0) {
    return undefined;
  }

  return inputs.map(input => toDate(input)).filter((input): input is Dayjs => !!input);
};

export const dateToIsoDateString = (date: Dayjs): string => {
  return date.format(ISO_8601_DATE_FORMAT);
};

export const dateToIsoDateTimeString = (date: Dayjs): string => {
  return date.toISOString();
};

//TODO will be removed when BE java will be upgraded
export const dateToUtcIsoDateTimeString = (date: Dayjs): string => {
  return dayjs(date).utc().toISOString();
};

export const getDatePickerFormat = (): string[] => [
  dayjs.localeData().longDateFormat("L"),
  dayjs.localeData().longDateFormat("l"),
  "DD. MM. YYYY",
  "D. M. YYYY"
];

export const getDateTimePickerFormat = (): string =>
  dayjs.localeData().longDateFormat("L") + " " + dayjs.localeData().longDateFormat("LTS");

export const datePickerStandardProps: PickerProps = {
  format: getDatePickerFormat(),
  placeholder: "",
  allowClear: false,
  style: { width: "100%" }
};

export const datePickerClearableProps: PickerProps = {
  ...datePickerStandardProps,
  allowClear: true
};

export const datePickerDefaultRanges: TimeRangePickerProps["presets"] = [
  { label: t("common.thisWeek"), value: [dayjs().startOf("week"), dayjs().endOf("week")] },
  { label: t("common.thisMonth"), value: [dayjs().startOf("month"), dayjs().endOf("month")] },
  {
    label: t("common.lastMonth"),
    value: [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")]
  }
];

export const datePickerDefaultRangesUntilToday: TimeRangePickerProps["presets"] = [
  { label: t("common.today"), value: () => [dayjs().startOf("day"), dayjs().endOf("day")] },
  { label: t("common.thisWeek"), value: () => [dayjs().startOf("week"), dayjs().endOf("day")] },
  { label: t("common.thisMonth"), value: () => [dayjs().startOf("month"), dayjs().endOf("day")] },
  {
    label: t("common.lastMonth"),
    value: () => [dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month")]
  }
];

export const datePickerFormItemProps: Pick<FormItemProps, "getValueProps" | "normalize"> = {
  getValueProps: (value: any) => ({ value: value && dayjs(value) }),
  normalize: (value: any) => (value ? dateToIsoDateString(value) : undefined)
};

export const rangePickerFormItemProps = (
  picker: GetProp<DatePickerProps, "picker"> = "date"
): Pick<FormItemProps, "getValueProps" | "normalize"> => {
  return {
    getValueProps: (value?: string[]) => {
      if (value && Array.isArray(value)) {
        if (picker === "year") {
          return { value: value.map(item => dayjs(`${item}-01-01`)) };
        }

        return { value: value.map(item => dayjs(item)) };
      }

      return { value: undefined };
    },
    normalize: (value?: [Dayjs, Dayjs]) => {
      let result: Dayjs[] = [];

      if (value && Array.isArray(value)) {
        switch (picker) {
          case "year":
            return value.map(item => item.year());
          case "quarter":
            const startQuarter = value[0].startOf("month");
            const quarterEndMonth = Math.ceil((value[1].month() + 1) / 3) * 3;
            const endQuarter = value[1].month(quarterEndMonth - 1).endOf("month");

            result.push(startQuarter);
            result.push(endQuarter.isBefore(dayjs()) ? endQuarter : dayjs());
            break;
          case "month":
            const startMonth = value[0].startOf("month");
            const endMonth = value[1].endOf("month");

            result.push(startMonth);
            result.push(endMonth.isBefore(dayjs()) ? endMonth : dayjs());
            break;
          case "week":
            const startWeek = value[0].startOf("week");
            const endWeek = value[1].endOf("week");

            result.push(startWeek);
            result.push(endWeek.isBefore(dayjs()) ? endWeek : dayjs());
            break;
          case "time":
            return value.map(item => dateToUtcIsoDateTimeString(item));
          default:
            result = value;
            break;
        }

        return result.map(item => dateToIsoDateString(item));
      }

      return undefined;
    }
  };
};

export const dateTimePickerStandardProps: PickerProps<Dayjs> = {
  format: getDateTimePickerFormat(),
  placeholder: "",
  allowClear: false
};

export const dateTimePickerClearableProps: PickerProps<Dayjs> = {
  ...dateTimePickerStandardProps,
  allowClear: true
};

export const disableDatePickerPresentAndFuture = (checked: Dayjs): boolean => {
  return checked.isSameOrAfter(dayjs(), "day");
};

export const disableDatePickerFuture = (checked: Dayjs): boolean => {
  return checked.isAfter(dayjs(), "day");
};

export const disableDatePickerPresentAndPast = (checked: Dayjs): boolean => {
  return checked.isSameOrBefore(dayjs(), "day");
};

export const disableDatePickerPast = (checked: Dayjs): boolean => {
  return checked.isBefore(dayjs(), "day");
};

export const disableDatePickerPresent = (checked: Dayjs): boolean => {
  return checked.isSame(dayjs(), "day");
};

export const disableDatePickerOutOfInterval = (checked: Dayjs, min: Dayjs | string, max: Dayjs | string): boolean => {
  return checked.isBefore(min, "day") || checked.isAfter(max, "day");
};

export const disableDatePickerOutOfMinDate = (checked: Dayjs, min: Dayjs | string): boolean => {
  return checked.isBefore(min, "day");
};

export const disableDatePickerOutOfMinDateIncluded = (checked: Dayjs, min: Dayjs | string): boolean => {
  return checked.isSameOrBefore(min, "day");
};

export const disableDatePickerOutOfMaxDate = (checked: Dayjs, max: Dayjs | string): boolean => {
  return checked.isAfter(max, "day");
};

export const disableDatePickerOutOfMaxDateIncluded = (checked: Dayjs, max: Dayjs | string): boolean => {
  return checked.isSameOrAfter(max, "day");
};

export const selectFilterFunction = (input: string, option?: DefaultOptionType): boolean => {
  if (!option) {
    return false;
  }

  return (
    latinise((option.label?.toString() || option.children?.toString())?.toLowerCase()).indexOf(
      latinise(input.toLowerCase())
    ) !== -1
  );
};

export const selectTagsFilterFunction = (
  input: string,
  translationsMap: Map<string, string>,
  option?: DefaultOptionType
): boolean => {
  if (!option) {
    return false;
  }

  return latinise((option.value ? translationsMap.get(option.value.toString()) : "")?.toLowerCase()).includes(
    latinise(input.toLowerCase())
  );
};

export const selectStandardProps: SelectProps = {
  showSearch: true,
  filterOption: selectFilterFunction,
  popupMatchSelectWidth: false
};

export const selectTagsStandardProps = (translationsMap: Map<string, string>): SelectProps => ({
  showSearch: true,
  filterOption: (inputValue, option) => selectTagsFilterFunction(inputValue, translationsMap, option),
  popupMatchSelectWidth: false
});

export const treeNodeFilterFunction = (input: string, treeNode: Record<string, any>): boolean => {
  return latinise(treeNode["title"].toLowerCase()).indexOf(latinise(input.toLowerCase())) !== -1;
};

export const mapInstitutionTreeSelectValuesToInstitutionIds = (
  treeSelectValues: string[],
  institutionsEnums: InstitutionWithSettings[]
): UUID[] => {
  const institutionTypes = Object.keys(InstitutionType);
  return treeSelectValues
    ?.map(idOrType =>
      institutionTypes.includes(idOrType)
        ? institutionsEnums
            .filter(institution => institution.type === idOrType && !institution.settings.deactivated)
            .map(institution => institution.id)
        : [idOrType]
    )
    .flat();
};

export const mapProductTreeSelectValuesToProductIds = (
  treeSelectValues: UUID[],
  productGroupsEnums: ProductGroupWithProducts[]
): UUID[] => {
  return treeSelectValues
    ?.map(id => productGroupsEnums.find(group => group.id === id)?.products.map(product => product.id) || [id])
    .flat();
};

export const inputNumberIntegerStandardProps: InputNumberProps<number | string> = {
  style: { width: "100%" },
  formatter: value => {
    if (!value) {
      return "";
    }
    if (value === "-") {
      return "-";
    }

    return Intl.NumberFormat(DEFAULT_LOCALE).format(value as number);
  },
  parser: displayValue =>
    ((displayValue?.startsWith("-") ? "-" : "") +
      displayValue?.replace(NON_DIGIT_CHARACTERS_REGEX, "")) as unknown as number
};

export const inputNumberDecimalStandardProps: InputNumberProps<number | string> = {
  style: { width: "100%" },
  formatter: value => {
    if (!value) {
      return "";
    }
    if (value === "-") {
      return "-";
    }

    return Intl.NumberFormat(DEFAULT_LOCALE).format(value as number);
  },
  precision: 2,
  decimalSeparator: ","
};

export const threeSpacedStringNormalizeFunction = (input: string): string => {
  return input
    ? input
        .replace(ALL_WHITE_SPACES_PATTERN, "")
        .replace(EVERY_THIRD_CHAR_PATTERN, "$1 ")
        .replace(BEGINNING_END_WHITE_SPACES_PATTER, "")
    : input;
};

export const fourSpacedStringNormalizeFunction = (input: string): string => {
  return input
    ? input
        .replace(ALL_WHITE_SPACES_PATTERN, "")
        .replace(EVERY_FOURTH_CHAR_PATTERN, "$1 ")
        .replace(BEGINNING_END_WHITE_SPACES_PATTER, "")
    : input;
};

export const upperCaseStringNormalizeFunction = (input: string): string => (input ? input.toUpperCase() : input);

export const licensePlateNormalizeFunction = (input: string): string => formatLicensePlate(input);

export const phoneNumberNormalizeFunction = (value: string): string =>
  value ? new AsYouType("SK").input(value) : value;

export const ibanNormalizeFunction = (input: string): string =>
  input ? fourSpacedStringNormalizeFunction(input).toUpperCase() : input;

export const fillBirthDateFromPin = (pin: string, form: FormInstance, birthDateFormKey: string = "birthDate"): void => {
  if (!pin || !validationFunctions.validatePin(pin)) {
    form.setFieldsValue({ [birthDateFormKey]: undefined });
  } else {
    form.setFieldsValue({ [birthDateFormKey]: parseBirthDateFromPin(pin) });
  }
};

export const sortOrderToOrderDirection = (sortOrder: SortOrder): OrderDirection | undefined => {
  switch (sortOrder) {
    case "ascend":
      return OrderDirection.ASC;
    case "descend":
      return OrderDirection.DESC;
    default:
      return undefined;
  }
};

export const orderDirectionToSortOrder = (orderDirection?: OrderDirection): SortOrder | undefined => {
  if (!orderDirection) {
    return undefined;
  }
  switch (orderDirection) {
    case OrderDirection.ASC:
      return "ascend";
    case OrderDirection.DESC:
      return "descend";
    default:
      return undefined;
  }
};

export const quillEditorStandardProps = {
  theme: "snow",
  modules: {
    toolbar: [
      [{ size: ["small", false, "large"] }],
      ["bold", "italic", "underline", "strike", { color: [] }],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "clean"]
    ]
  },
  formats: [
    "header",
    "size",
    "code",
    "code-block",
    "blockquote",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "list",
    "indent",
    "link"
  ]
};
