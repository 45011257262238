import { Card, Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { SearchPageResult } from "../../../../common/types";
import { formatAgentName } from "../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { deleteConfigPropertyActions } from "../../ducks";
import { DynamicConfigProperty } from "../../types";
import { CONFIG_PROPERTY_FEATURE_KEYS } from "../../utils";

interface Props {
  propertiesCurrentPage: SearchPageResult<DynamicConfigProperty>;
  onPageChange: (pageNumber: number) => void;
  onUpdateClick: (property: DynamicConfigProperty) => void;
  onDelete: typeof deleteConfigPropertyActions.request;
}

const ConfigPropertyTableView = ({ propertiesCurrentPage, onPageChange, onUpdateClick, onDelete }: Props) => {
  const [visibleProperties, setVisibleProperties] = useState<boolean[]>(
    Array.from(propertiesCurrentPage.pageData, () => false)
  );

  useEffect(() => {
    setVisibleProperties(Array.from(propertiesCurrentPage.pageData, () => false));
  }, [propertiesCurrentPage]);

  const handlePropertyVisibilityToggle = (index: number): void => {
    const newVisibleProperties = [...visibleProperties];
    newVisibleProperties[index] = !visibleProperties[index];
    setVisibleProperties(newVisibleProperties);
  };

  const columns: ColumnsType<DynamicConfigProperty> = [
    {
      key: "key",
      title: t("configProperty.attrs.key"),
      width: 300,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.key}</Ellipsis>
    },
    {
      key: "value",
      align: "center",
      width: 50,
      render: (_, record, index) =>
        record.encrypted ? (
          visibleProperties[index] ? (
            <ActionTextIcon icon="eye-invisible" color="red" onClick={() => handlePropertyVisibilityToggle(index)} />
          ) : (
            <ActionTextIcon icon="eye" color="blue" onClick={() => handlePropertyVisibilityToggle(index)} />
          )
        ) : null
    },
    {
      key: "value",
      title: t("configProperty.attrs.value"),
      width: 220,
      ellipsis: { showTitle: false },
      render: (_, record, index) => (
        <Ellipsis>
          {record.encrypted
            ? visibleProperties[index]
              ? record.value
              : "*************"
            : CONFIG_PROPERTY_FEATURE_KEYS.includes(record.key)
              ? record.value
                  .split(",")
                  .map(v => t("agent.enums.feature." + v.trim()))
                  .join(", ")
              : record.value}
        </Ellipsis>
      )
    },
    {
      key: "ownedBy",
      title: t("configProperty.attrs.ownedById"),
      width: 150,
      ellipsis: { showTitle: false },
      render: (_, record) => record.ownedBy && <Ellipsis>{formatAgentName(record.ownedBy, false)}</Ellipsis>
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onUpdateClick(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("configProperty.helpers.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => onDelete({ id: record.id })}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Card className="card-box">
      <Table<DynamicConfigProperty>
        {...tableStandardProps()}
        columns={columns}
        dataSource={propertiesCurrentPage.pageData}
        scroll={{ x: TableSizes.MEDIUM }}
        pagination={{
          ...paginationTableProps,
          current: propertiesCurrentPage.pageIndex + 1,
          pageSize: propertiesCurrentPage.pageSize,
          total: propertiesCurrentPage.totalElementsCount,
          onChange: onPageChange
        }}
      />
    </Card>
  );
};

export default ConfigPropertyTableView;
