import { Card, Checkbox, Col, DatePicker, Divider, Form, InputNumber, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import React from "react";
import t from "../../../../../../app/i18n";
import InputAddon from "../../../../../../common/components/form/addons/InputAddon";
import LabelWithPopover from "../../../../../../common/components/form/labels/LabelWithPopover";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../common/constants";
import {
  datePickerClearableProps,
  datePickerFormItemProps,
  datePickerStandardProps,
  disableDatePickerOutOfInterval,
  disableDatePickerPresentAndFuture,
  inputNumberIntegerStandardProps,
  selectStandardProps
} from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import {
  TravelClientDiscountType,
  TravelInsuranceType,
  TravelLuggageInsuranceAmount,
  TravelRiskGroup,
  TravelTerritorialValidity
} from "../../enums";
import { TravelCalc } from "../../types";

interface Props {
  form: FormInstance<TravelCalc>;
  insuranceType: TravelInsuranceType;
}

const TravelCalcForm = ({ form, insuranceType }: Props) => {
  const handleRiskGroupChange = (riskGroup: TravelRiskGroup): void => {
    if (riskGroup === TravelRiskGroup.MANUAL_WORK) {
      form.setFieldsValue({ discountsData: { unionHealthInsuranceClient: false } });
    }
  };

  const handleInsuredClientBirthDateChange = (): void => {
    form.validateFields([["discountsData"]]);
  };

  const colSpan = 4;
  const beginningDateFrom = dayjs();
  const beginningDateTo = dayjs().add(18, "month");
  const firstPaymentDateFrom = dayjs().subtract(1, "years");
  const firstPaymentDateTo = dayjs();

  return (
    <>
      <Card type="inner" className="card-box" title={t("calc.travel.sections.generalData")}>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name={["generalData", "effectiveBeginningDate"]}
              label={t("calc.travel.attrs.generalData.effectiveBeginningDate")}
              rules={[validations.notNull, validations.dateInInterval(beginningDateFrom, beginningDateTo)]}
              {...datePickerFormItemProps}
            >
              <DatePicker
                {...datePickerStandardProps}
                disabledDate={current => disableDatePickerOutOfInterval(current, beginningDateFrom, beginningDateTo)}
              />
            </Form.Item>
          </Col>

          {insuranceType === TravelInsuranceType.SHORT_TERM || insuranceType === TravelInsuranceType.CANCELLATION ? (
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.generalData?.effectiveBeginningDate !== next.generalData?.effectiveBeginningDate
              }
            >
              {({ getFieldValue }) => {
                const effectiveBeginningDate = getFieldValue(["generalData", "effectiveBeginningDate"]) as string;
                const endDateFrom = effectiveBeginningDate ? effectiveBeginningDate : dayjs();
                const endDateTo = effectiveBeginningDate
                  ? dayjs(effectiveBeginningDate).add(1, "year")
                  : dayjs().add(1, "year");
                return (
                  <Col span={colSpan}>
                    <Form.Item
                      name={["generalData", "effectiveEndDate"]}
                      label={t("calc.travel.attrs.generalData.effectiveEndDate")}
                      dependencies={[["generalData", "effectiveBeginningDate"]]}
                      rules={[validations.notNull, validations.dateInInterval(endDateFrom, endDateTo)]}
                      {...datePickerFormItemProps}
                    >
                      <DatePicker
                        {...datePickerStandardProps}
                        showToday={false}
                        disabledDate={current => disableDatePickerOutOfInterval(current, endDateFrom, endDateTo)}
                      />
                    </Form.Item>
                  </Col>
                );
              }}
            </Form.Item>
          ) : null}

          <Col span={colSpan}>
            <Form.Item
              name={["generalData", "territorialValidity"]}
              label={t("calc.travel.enums.territorialValidity._label")}
              rules={[validations.notNull]}
            >
              <Select
                {...selectStandardProps}
                options={Object.keys(TravelTerritorialValidity).map(validity => ({
                  value: validity,
                  label: t("calc.travel.enums.territorialValidity." + validity)
                }))}
              />
            </Form.Item>
          </Col>

          {insuranceType === TravelInsuranceType.SHORT_TERM || insuranceType === TravelInsuranceType.YEAR ? (
            <Col span={colSpan}>
              <Form.Item
                name={["generalData", "riskGroup"]}
                label={t("calc.travel.enums.riskGroup._label")}
                rules={[validations.notNull]}
              >
                <Select
                  {...selectStandardProps}
                  options={Object.keys(TravelRiskGroup).map(group => ({
                    value: group,
                    label: t("calc.travel.enums.riskGroup." + group)
                  }))}
                  onChange={handleRiskGroupChange}
                />
              </Form.Item>
            </Col>
          ) : null}

          {insuranceType === TravelInsuranceType.YEAR && (
            <Col span={colSpan}>
              <Form.Item
                name={["generalData", "studyStay"]}
                className="form-item-without-label"
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("calc.travel.attrs.generalData.studyStay")}</Checkbox>
              </Form.Item>
            </Col>
          )}
        </Row>

        {insuranceType === TravelInsuranceType.CANCELLATION && (
          <>
            <Divider className="divider-subheader">{t("calc.travel.sections.cancellationData")}</Divider>
            <Row gutter={rowGutter}>
              <Col span={5}>
                <Form.Item
                  name={["cancellationData", "tripPrice"]}
                  label={t("calc.travel.attrs.cancellationData.tripPrice")}
                  rules={[validations.notNull, validations.minNumber(100), validations.multipleOf100]}
                >
                  <InputNumber
                    {...inputNumberIntegerStandardProps}
                    addonAfter={<InputAddon type="euro" />}
                    step={100}
                  />
                </Form.Item>
              </Col>

              <Col span={5}>
                <Form.Item
                  name={["cancellationData", "firstPaymentDate"]}
                  label={t("calc.travel.attrs.cancellationData.firstPaymentDate")}
                  rules={[validations.notFuture, validations.dateInInterval(firstPaymentDateFrom, firstPaymentDateTo)]}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerClearableProps}
                    disabledDate={current =>
                      disableDatePickerOutOfInterval(current, firstPaymentDateFrom, firstPaymentDateTo)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Card>

      <Card type="inner" className="card-box" title={t("calc.travel.sections.insuredPersons")}>
        <Row gutter={rowGutter}>
          <Col span={colSpan}>
            <Form.Item
              name={["clientsData", "insuredClientsCount"]}
              label={t("calc.travel.attrs.clientsData.insuredClientsCount")}
              rules={[validations.notNull, validations.minNumber(1), validations.maxNumber(60)]}
            >
              <InputNumber style={{ width: "100%" }} min={1} max={60} />
            </Form.Item>
          </Col>

          <Form.Item noStyle shouldUpdate={(prev, next) => prev.generalData?.riskGroup !== next.generalData?.riskGroup}>
            {({ getFieldValue }) => {
              const manualWorkSelected = getFieldValue(["generalData", "riskGroup"]) === TravelRiskGroup.MANUAL_WORK;
              return (
                (insuranceType === TravelInsuranceType.SHORT_TERM || insuranceType === TravelInsuranceType.YEAR) && (
                  <>
                    <Col span={9} offset={11}>
                      <Divider className="divider-subheader" style={{ margin: "0 0 9px 0" }}>
                        {t("calc.travel.sections.additionalInfo")}
                      </Divider>

                      <Row gutter={rowGutter}>
                        <Col span={10}>
                          <Form.Item
                            name={["discountsData", "familyInsurance"]}
                            valuePropName="checked"
                            rules={[validations.none]}
                            initialValue={false}
                          >
                            <Checkbox>
                              <LabelWithPopover
                                label={t("calc.travel.attrs.discountsData.familyInsurance")}
                                popoverTitle={t("calc.travel.helpers.familyInsuranceDesc.title")}
                                popoverContent={
                                  <table className="data-table-view wide-margins" style={{ maxWidth: 550 }}>
                                    <tbody>
                                      {[1, 2].map(value => (
                                        <tr key={value}>
                                          <td>{t("calc.travel.helpers.familyInsuranceDesc.institution" + value)}</td>
                                          <td>{t("calc.travel.helpers.familyInsuranceDesc.value" + value)}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                }
                              />
                            </Checkbox>
                          </Form.Item>
                        </Col>

                        {insuranceType === TravelInsuranceType.YEAR && (
                          <Col span={14}>
                            <Form.Item
                              name={["discountsData", "unionHealthInsuranceClient"]}
                              valuePropName="checked"
                              rules={[validations.none]}
                              initialValue={false}
                            >
                              <Checkbox disabled={manualWorkSelected}>
                                <LabelWithTooltip
                                  label={t("calc.travel.attrs.discountsData.unionHealthInsuranceClient")}
                                  tooltip={t("calc.travel.helpers.unionHealthInsuranceClientDesc")}
                                />
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </>
                )
              );
            }}
          </Form.Item>
        </Row>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.clientsData?.insuredClientsCount !== next.clientsData?.insuredClientsCount}
        >
          {({ getFieldValue }) =>
            (insuranceType === TravelInsuranceType.SHORT_TERM || insuranceType === TravelInsuranceType.YEAR) && (
              <Row gutter={rowGutter}>
                {[...Array(getFieldValue(["clientsData", "insuredClientsCount"]) || 0)].map((_, index) => (
                  <React.Fragment key={index}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["clientsData", "insuredClients", index, "birthDate"]}
                        label={t("calc.travel.attrs.clientsData.insuredClients.birthDateLabel", { number: index + 1 })}
                        rules={[validations.notNull, validations.notPresentAndFuture]}
                        {...datePickerFormItemProps}
                      >
                        <DatePicker
                          {...datePickerStandardProps}
                          showToday={false}
                          disabledDate={disableDatePickerPresentAndFuture}
                          onChange={handleInsuredClientBirthDateChange}
                        />
                      </Form.Item>
                    </Col>

                    {insuranceType === TravelInsuranceType.SHORT_TERM && (
                      <>
                        <Col span={colSpan - 1}>
                          <Form.Item
                            name={["clientsData", "insuredClients", index, "discountType"]}
                            label={t("calc.travel.enums.clientDiscountType._label")}
                            rules={[validations.none]}
                          >
                            <Select
                              {...selectStandardProps}
                              allowClear
                              options={Object.keys(TravelClientDiscountType).map(discount => ({
                                value: discount,
                                label: t("calc.travel.enums.clientDiscountType." + discount)
                              }))}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={1} />
                      </>
                    )}
                  </React.Fragment>
                ))}
              </Row>
            )
          }
        </Form.Item>
      </Card>

      {(insuranceType === TravelInsuranceType.SHORT_TERM || insuranceType === TravelInsuranceType.YEAR) && (
        <Card type="inner" className="card-box" title={t("calc.travel.sections.coverages")}>
          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                name={["coveragesData", "injury"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue
              >
                <Checkbox>{t("calc.travel.attrs.coveragesData.injury")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan * 2} offset={colSpan * 2}>
              <Form.Item
                name={["coveragesData", "airplaneTravel"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  <LabelWithTooltip
                    label={t("calc.travel.attrs.coveragesData.airplaneTravel")}
                    tooltip={t("calc.travel.helpers.coveragesData.airplaneTravelDesc")}
                  />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                name={["coveragesData", "liability"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue
              >
                <Checkbox>{t("calc.travel.attrs.coveragesData.liability")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan * 2} offset={colSpan * 2}>
              <Form.Item
                name={["coveragesData", "vehicleRental"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  <LabelWithTooltip
                    label={t("calc.travel.attrs.coveragesData.vehicleRental")}
                    tooltip={t("calc.travel.helpers.coveragesData.vehicleRentalDesc")}
                  />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={colSpan}>
              <Form.Item
                name={["coveragesData", "rescueService"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("calc.travel.attrs.coveragesData.rescueService")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan} offset={colSpan * 2}>
              <Form.Item
                name={["coveragesData", "travelDocuments"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("calc.travel.attrs.coveragesData.travelDocuments")}</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.coveragesData.luggage !== next.coveragesData.luggage}>
              {({ getFieldValue }) => {
                const showAmountField =
                  getFieldValue(["coveragesData", "luggage"]) && insuranceType === TravelInsuranceType.SHORT_TERM;
                return (
                  <>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["coveragesData", "luggage"]}
                        className={showAmountField ? "form-item-without-label" : undefined}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox>{t("calc.travel.attrs.coveragesData.luggage")}</Checkbox>
                      </Form.Item>
                    </Col>

                    {showAmountField && (
                      <Col span={colSpan}>
                        <Form.Item
                          name={["coveragesData", "luggageInsuranceAmount"]}
                          label={t("calc.travel.enums.luggageInsuranceAmount._label")}
                          rules={[validations.notNull]}
                        >
                          <Select
                            {...selectStandardProps}
                            options={Object.keys(TravelLuggageInsuranceAmount).map(amount => ({
                              value: amount,
                              label: t("calc.travel.enums.luggageInsuranceAmount." + amount)
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col span={colSpan} offset={showAmountField ? colSpan : colSpan * 2}>
                      <Form.Item
                        name={["coveragesData", "electronicDevices"]}
                        className={showAmountField ? "form-item-without-label" : undefined}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox>{t("calc.travel.attrs.coveragesData.electronicDevices")}</Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={colSpan * 2}>
              <Form.Item
                name={["coveragesData", "covid"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>{t("calc.travel.attrs.coveragesData.covid")}</Checkbox>
              </Form.Item>
            </Col>

            <Col span={colSpan} offset={colSpan}>
              <Form.Item
                name={["coveragesData", "pet"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  <LabelWithTooltip
                    label={t("calc.travel.attrs.coveragesData.pet")}
                    tooltip={t("calc.travel.helpers.coveragesData.petDesc")}
                  />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          {insuranceType === TravelInsuranceType.SHORT_TERM && (
            <Row gutter={rowGutter}>
              <Col span={colSpan * 2}>
                <Form.Item
                  name={["coveragesData", "vehicleAssistance"]}
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue={false}
                >
                  <Checkbox>
                    <LabelWithTooltip
                      label={t("calc.travel.attrs.coveragesData.vehicleAssistance")}
                      tooltip={t("calc.travel.helpers.coveragesData.vehicleAssistanceDesc")}
                    />
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col span={colSpan * 2} offset={colSpan}>
                <Form.Item
                  name={["coveragesData", "abandonedHousehold"]}
                  valuePropName="checked"
                  rules={[validations.none]}
                  initialValue={false}
                >
                  <Checkbox>
                    <LabelWithTooltip
                      label={t("calc.travel.attrs.coveragesData.abandonedHousehold")}
                      tooltip={t("calc.travel.helpers.coveragesData.abandonedHouseholdDesc")}
                    />
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={rowGutter}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.coveragesData.cancellation !== next.coveragesData.cancellation}
            >
              {({ getFieldValue }) => {
                const showDataFields = getFieldValue(["coveragesData", "cancellation"]);
                return (
                  <>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["coveragesData", "cancellation"]}
                        className={showDataFields ? "form-item-without-label" : undefined}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox>{t("calc.travel.attrs.coveragesData.cancellation")}</Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={colSpan * 2}>
                      {showDataFields && (
                        <Row gutter={rowGutter}>
                          <Col span={12}>
                            <Form.Item
                              name={["coveragesData", "cancellationData", "tripPrice"]}
                              label={t("calc.travel.attrs.coveragesData.cancellationData.tripPrice")}
                              rules={[
                                ...(insuranceType === TravelInsuranceType.SHORT_TERM ? [validations.notNull] : []),
                                validations.minNumber(100),
                                validations.multipleOf100
                              ]}
                            >
                              <InputNumber
                                {...inputNumberIntegerStandardProps}
                                addonAfter={<InputAddon type="euro" />}
                                step={100}
                              />
                            </Form.Item>
                          </Col>

                          {insuranceType === TravelInsuranceType.SHORT_TERM && (
                            <>
                              <Col span={12}>
                                <Form.Item
                                  name={["coveragesData", "cancellationData", "complicity"]}
                                  valuePropName="checked"
                                  className="form-item-without-label"
                                  rules={[validations.none]}
                                  initialValue={true}
                                >
                                  <Checkbox>
                                    {t("calc.travel.attrs.coveragesData.cancellationData.complicity")}
                                  </Checkbox>
                                </Form.Item>
                              </Col>

                              <Col span={12}>
                                <Form.Item
                                  name={["coveragesData", "cancellationData", "firstPaymentDate"]}
                                  label={t("calc.travel.attrs.coveragesData.cancellationData.firstPaymentDate")}
                                  rules={[
                                    validations.notFuture,
                                    validations.dateInInterval(firstPaymentDateFrom, firstPaymentDateTo)
                                  ]}
                                  {...datePickerFormItemProps}
                                >
                                  <DatePicker
                                    {...datePickerClearableProps}
                                    disabledDate={current =>
                                      disableDatePickerOutOfInterval(current, firstPaymentDateFrom, firstPaymentDateTo)
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={12}>
                                <Form.Item
                                  name={["coveragesData", "cancellationData", "extendedCoverage"]}
                                  valuePropName="checked"
                                  className="form-item-without-label"
                                  rules={[validations.none]}
                                  initialValue={false}
                                >
                                  <Checkbox style={{ maxHeight: 22 }}>
                                    {t("calc.travel.attrs.coveragesData.cancellationData.extendedCoverage")}
                                  </Checkbox>
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </Row>
                      )}
                    </Col>

                    <Col span={colSpan * 2}>
                      <Form.Item
                        name={["coveragesData", "drinkAllowed"]}
                        className={showDataFields ? "form-item-without-label" : undefined}
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox>
                          <LabelWithTooltip
                            label={t("calc.travel.attrs.coveragesData.drinkAllowed")}
                            tooltip={t("calc.travel.helpers.coveragesData.drinkAllowedDesc")}
                          />
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
        </Card>
      )}
    </>
  );
};

export default TravelCalcForm;
