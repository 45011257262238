import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import t from "../../../../../../../app/i18n";
import ActionTextIcon from "../../../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../../../common/components/icons/PopconfirmDeleteIcon";
import StatusTag from "../../../../../../../common/components/tags/StatusTag";
import { PageSizes, TableSizes } from "../../../../../../../common/constants";
import { Permission } from "../../../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../../../common/types";
import { formatLocaleCurrencyWithNullAsZero } from "../../../../../../../common/utils/formatUtils";
import { paginationTableProps, tableStandardProps } from "../../../../../../../common/utils/utils";
import { selectHasPermissions } from "../../../../../../auth/ducks";
import InstitutionTypeTagWithName from "../../../../../../institution/components/InstitutionTypeTagWithName";
import { deleteCommissionsUnitActions } from "../../../../ducks";
import { CommissionsBatchStep } from "../../../../enums";
import {
  CommissionsBatch,
  CommissionsBatchAttachmentActions,
  CommissionsBatchInputAttachment,
  CommissionsUnit
} from "../../../../types";
import CommissionsUnitAttachmentsTableView from "./CommissionsUnitAttachmentsTableView";

interface Props {
  batch: CommissionsBatch;
  attachmentActions?: CommissionsBatchAttachmentActions;
  onDelete?: typeof deleteCommissionsUnitActions.request;
  onUpdateClick?(unit: CommissionsUnit): void;
}

const CommissionsUnitTableView = ({ batch, ...props }: Props) => {
  const hasManageCommissionsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.COMMISSIONS_MANAGE)(state)
  );

  const columns: ColumnsType<CommissionsUnit> = [
    {
      key: "institution",
      title: t("commissions.batch.attrs.unit.institution"),
      width: 232,
      ellipsis: { showTitle: false },
      render: (_, record) => <InstitutionTypeTagWithName institution={record.institution} ellipsis />
    },
    {
      key: "allCommissionAmount",
      title: t("commissions.batch.attrs.unit.allCommissionAmount"),
      align: "right",
      width: 110,
      render: (_, record) => formatLocaleCurrencyWithNullAsZero(record.allCommissionAmount)
    },
    {
      key: "postponedCommissionAmount",
      title: t("commissions.batch.attrs.unit.postponedCommissionAmount"),
      align: "right",
      width: 110,
      render: (_, record) => formatLocaleCurrencyWithNullAsZero(record.postponedCommissionAmount)
    },
    {
      key: "bankAccountCommissionAmount",
      title: t("commissions.batch.attrs.unit.bankAccountCommissionAmount"),
      align: "right",
      width: 110,
      render: (_, record) => formatLocaleCurrencyWithNullAsZero(record.bankAccountCommissionAmount)
    },
    {
      key: "invoiceCommissionAmount",
      title: t("commissions.batch.attrs.unit.invoiceCommissionAmount"),
      align: "right",
      width: 110,
      render: (_, record) => formatLocaleCurrencyWithNullAsZero(record.invoiceCommissionAmount)
    },
    {
      key: "volumeCommissionAmount",
      title: t("commissions.batch.attrs.unit.volumeCommissionAmount"),
      align: "right",
      width: 110,
      render: (_, record) => formatLocaleCurrencyWithNullAsZero(record.volumeCommissionAmount)
    }
  ];

  if (batch.step !== CommissionsBatchStep.FINISH) {
    columns.push({
      key: "statusTags",
      width: 140,
      render: (_, record) => {
        const attachments = (batch.attachments as CommissionsBatchInputAttachment[]).filter(
          attachment => attachment.institution?.id === record.institution.id
        );
        return (
          <>
            {attachments.some(attachment => attachment.hasImportErrors) && (
              <StatusTag status="error" tooltip={t("commissions.batch.helpers.importedAttachmentError")} />
            )}
            {attachments.some(attachment => attachment.importInProgress) && <StatusTag status="processing" />}
          </>
        );
      }
    });
  }

  if (batch.step !== CommissionsBatchStep.FINISH && hasManageCommissionsPermission) {
    columns.push({
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon
            icon="edit"
            color="blue"
            text={t("common.edit")}
            onClick={() => props.onUpdateClick?.(record)}
          />

          <Divider type="vertical" />

          <Popconfirm
            title={t("commissions.batch.helpers.deleteUnitConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() =>
              props.onDelete?.({
                id: batch.id,
                object: { institutionId: record.institution.id }
              })
            }
          >
            <span>
              <ActionTextIcon
                icon="delete"
                color="red"
                text={t("common.delete")}
                disabled={
                  batch.stepChangeInProgress ||
                  (batch.attachments as CommissionsBatchInputAttachment[])
                    .filter(attachment => attachment.institution?.id === record.institution.id)
                    .some(attachment => attachment.importInProgress)
                }
              />
            </span>
          </Popconfirm>
        </>
      )
    });
  }

  return (
    <Table<CommissionsUnit>
      {...tableStandardProps()}
      columns={columns}
      scroll={{ x: TableSizes.LARGE }}
      dataSource={batch.commissionsUnits}
      expandable={{
        expandedRowRender: record => (
          <CommissionsUnitAttachmentsTableView batch={batch} unit={record} actions={props.attachmentActions} />
        )
      }}
      pagination={{
        ...paginationTableProps,
        pageSize: PageSizes.MEDIUM,
        total: batch.commissionsUnits.length
      }}
    />
  );
};

export default CommissionsUnitTableView;
