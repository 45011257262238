import { Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import {
  formatAgentName,
  formatLocaleDate,
  formatLocalePercentageNumber
} from "../../../../../common/utils/formatUtils";
import { isDefined, tableStandardProps } from "../../../../../common/utils/utils";
import type { UUID } from "../../../../../typings/global";
import { deleteCommissionsSettingsRuleActions } from "../../ducks";
import { CommissionsSettingsInterval, CommissionsSettingsRule } from "../../types";
import CommissionSourceTag from "../CommissionSourceTag";
import CommissionsSettingsRuleView from "./CommissionsSettingsRuleView";

interface Props {
  rules: CommissionsSettingsRule[];
  agentId?: UUID;
  showActions: boolean;
  onUpdateClick?: (rule: CommissionsSettingsRule) => void;
  onDelete?: typeof deleteCommissionsSettingsRuleActions.request;
}

interface IntervalWithRule {
  interval: CommissionsSettingsInterval;
  rule: CommissionsSettingsRule;
  ruleIndex: number;
}

const CommissionsSettingsRuleTableView = ({ rules, agentId, showActions, onUpdateClick, onDelete }: Props) => {
  const intervalsWithRule = rules.flatMap((rule, ruleIndex) =>
    rule.intervals.map<IntervalWithRule>(interval => ({ interval, rule, ruleIndex }))
  );

  const handleOnCell = (record: IntervalWithRule, index?: number) => {
    if (!isDefined<number>(index)) {
      return { rowSpan: 0 };
    }

    return index === 0 || record.rule.id !== intervalsWithRule[index - 1]?.rule.id
      ? { rowSpan: record.rule.intervals.length }
      : { rowSpan: 0 };
  };

  const columns: ColumnsType<IntervalWithRule> = [
    {
      key: "rule",
      title: t("commissions.settings.sections.rule"),
      render: (_, record) => <CommissionsSettingsRuleView rule={record.rule} />,
      onCell: handleOnCell
    },
    {
      key: "intervals",
      title: t("commissions.settings.sections.intervals"),
      children: [
        {
          key: "startDate",
          title: t("commissions.settings.attrs.settings.startDate"),
          width: 110,
          render: (_, record) => formatLocaleDate(record.interval.startDate)
        },
        {
          key: "endDate",
          title: t("commissions.settings.attrs.settings.endDate"),
          width: 110,
          render: (_, record) => formatLocaleDate(record.interval.endDate)
        },
        {
          key: "commissionSource",
          title: t("commissions.settings.enums.commissionSource._label"),
          width: 115,
          render: (_, record) => <CommissionSourceTag source={record.interval.source} style={{ marginRight: 0 }} />
        },
        {
          key: "superiorAgentId",
          title: t("commissions.settings.attrs.settings.superiorAgentId"),
          width: 105,
          ellipsis: { showTitle: false },
          render: (_, record) => <Ellipsis>{formatAgentName(record.interval.superiorAgent)}</Ellipsis>
        },
        {
          key: "initialCommissionRate",
          title: t("commissions.settings.attrs.settings.initialCommissionRate"),
          align: "right",
          width: 110,
          render: (_, record) => formatLocalePercentageNumber(record.interval.initialCommissionRate)
        },
        {
          key: "subsequentCommissionRate",
          title: t("commissions.settings.attrs.settings.subsequentCommissionRate"),
          align: "right",
          width: 110,
          render: (_, record) => formatLocalePercentageNumber(record.interval.subsequentCommissionRate)
        }
      ]
    }
  ];

  if (showActions) {
    columns.push({
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon
            icon="edit"
            color="blue"
            text={t("common.edit")}
            onClick={() => onUpdateClick?.(record.rule)}
          />

          <Divider type="vertical" />

          <Popconfirm
            title={t("commissions.settings.helpers.deleteRuleConfirm")}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() => (agentId ? onDelete?.({ id1: agentId, id2: record.rule.id }) : undefined)}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </>
      ),
      onCell: handleOnCell
    });
  }

  return (
    <Table<IntervalWithRule>
      {...tableStandardProps()}
      rowKey={record => record.interval.id}
      rowClassName={record => (record.ruleIndex % 2 === 0 ? "table-row-light" : "table-row-dark")}
      columns={columns}
      scroll={{ x: TableSizes.LARGE }}
      dataSource={intervalsWithRule}
      pagination={false}
    />
  );
};

export default CommissionsSettingsRuleTableView;
