import { Popover } from "antd";
import { cssVariables } from "../../../utils/utils";
import AntIcon from "../../icons/AntIcon";

interface Props {
  label?: string;
  popoverTitle?: string;
  popoverContent: React.ReactNode;
  className?: string;
}

const LabelWithPopover = ({ label, popoverTitle, popoverContent, className }: Props) => (
  <span className={className}>
    {label && <>{label}&nbsp;</>}
    <Popover
      style={{ color: cssVariables.colorText }}
      title={popoverTitle ? <b style={{ color: cssVariables.colorText }}>{popoverTitle}</b> : undefined}
      content={popoverContent}
    >
      <span>
        <AntIcon type="question" className="cursor-help" />
      </span>
    </Popover>
  </span>
);

export default LabelWithPopover;
