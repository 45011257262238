export enum ProductFinancialSector {
  NON_LIFE_INSURANCES = "NON_LIFE_INSURANCES",
  LIFE_INSURANCES = "LIFE_INSURANCES",
  LOANS_AND_MORTGAGES = "LOANS_AND_MORTGAGES",
  CAPITAL_MARKET = "CAPITAL_MARKET",
  DEPOSITS = "DEPOSITS",
  SENIOR_PENSION_SAVINGS = "SENIOR_PENSION_SAVINGS",
  SUPPLEMENTARY_PENSION_SAVINGS = "SUPPLEMENTARY_PENSION_SAVINGS",
  OTHERS = "OTHERS",
  ALL = "ALL"
}

export enum ProductGroupCategory {
  RISK = "RISK",
  RESERVE = "RESERVE",
  PROPERTY = "PROPERTY",
  FUTURE = "FUTURE"
}
